import { Grid, IconButton } from "@material-ui/core";
import Report from "Components/Report";
import { stringSort, toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { clearValues } from "store/actions/business/report";
import { State } from "store/reducers";
import { getTickets } from "store/actions/ticket/report";
import IReportTicket from "store/reducers/ticket/types/report";
import bankBillet from "models/bankBillet";
import dayjs from "dayjs";
import { MdPictureAsPdf } from "react-icons/md";
import Spinner from "Components/Spinner";
import TicketTemplatePDF from "../Pdf";
import { PDFExport } from "@progress/kendo-react-pdf";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import userBankSlip from "models/userBankSlip";
import queryString from "query-string";

let getBusinessDebounced: (() => void) & _.Cancelable = null;

function TicketWiipoFlex({ history, location }) {
	const dispatch = useDispatch();
	const [item, setItem] = useState<any>();
	const table = useRef(null);
	const query = queryString.parse(location.search);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	const pdfExportComponent = useRef(null);

	const handleDownloadPDF = useCallback(() => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	}, []);

	const { items, pages, loading, value, count } = useSelector<
		State,
		IReportTicket
	>(s => s.ticketReport);

	const columns = useMemo(
		() =>
			[
				{
					Header: "Id",
					id: "id",
					accessor: c => (c.id ? c.id : "Não informado"),
					filterable: false,
					show: true,
					width: 300
				},
				{
					Header: ls.creationDate,
					id: "creationDate",
					accessor: c =>
						dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
					defaultSortDesc: true,
					width: 180,
					show: true,
					sortMethod: (a, b, _desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatWithoutSeconds);
						let bD = dayjs(b, ls.dateFormatWithoutSeconds);

						if (aD.isSame(bD)) {
							return 0;
						}

						return aD.isAfter(bD) ? 1 : -1;
					},
					Filter: DateSelectFilter,
					Footer: <div style={{ fontWeight: "bold" }}>{count} boletos</div>
				},
				{
					Header: ls.paymentDate,
					id: "updateDate",
					accessor: c =>
						dayjs(c.updateDate).format(ls.dateFormatWithoutSeconds),
					defaultSortDesc: true,
					width: 180,
					show: true,
					sortMethod: (a, b, _desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatWithoutSeconds);
						let bD = dayjs(b, ls.dateFormatWithoutSeconds);

						if (aD.isSame(bD)) {
							return 0;
						}

						return aD.isAfter(bD) ? 1 : -1;
					},
					Filter: DateSelectFilter
				},
				{
					Header: ls.economicGroup,
					id: "headquartersName",
					accessor: c => (
						<div style={{ width: "100%", textAlign: "center" }}>
							{c?.userShort?.headquartersName || "-"}
						</div>
					),
					show: true,
					sortMethod: stringSort,
					width: 300
				},
				{
					Header: ls.cnpj,
					id: "cnpj",
					accessor: c => c?.userShort?.businessCnpj || "-",
					show: true,
					filterable: false,
					sortMethod: stringSort,
					width: 300
				},
				{
					Header: ls.businessName,
					id: "businessName",
					accessor: c => c?.userShort?.businessName || "-",
					show: true,
					sortMethod: stringSort,
					width: 300
				},
				{
					Header: ls.name,
					id: "name",
					accessor: c => c?.userShort?.fullName,
					show: true,
					width: 210
				},
				{
					Header: ls.status,
					id: "status",
					accessor: c => ls[userBankSlip[c.status]],
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter?.value ?? query?.status ?? "all"}
						>
							<option value="">Todos</option>
							{Object.keys(userBankSlip).map(c => (
								<option key={c} value={c}>
									{ls[userBankSlip[c]]}
								</option>
							))}
						</select>
					),
					width: 260,
					show: true
				},
				{
					Header: ls.cpf,
					id: "cpf",
					accessor: c => c?.userShort?.cpf,
					show: true,
					width: 210
				},
				{
					Header: ls.balanceAccountGroup,
					id: "bag",
					accessor: c => c?.bag || "Livre",
					width: 200,
					show: true,
					filterable: false
				},
				{
					Header: ls.feeTicket,
					id: "calculatedFee",
					accessor: c => toStringCurrency(c?.calculatedFee / 100),
					show: true,
					width: 120,
					filterable: false
				},
				{
					Header: ls.cost,
					id: "cost",
					accessor: c => toStringCurrency(c?.cost / 100),
					show: true,
					width: 120,
					filterable: false
				},
				{
					Header: ls.balanceBefore,
					id: "availableBalanceBefore",
					accessor: c => toStringCurrency(c?.availableBalanceBefore / 100),
					show: true,
					width: 120,
					filterable: false
				},
				{
					Header: ls.balanceAfter,
					id: "availableBalanceAfter",
					accessor: c =>
						toStringCurrency(
							c.status === 2 ? (c?.availableBalanceBefore - c?.amount) / 100 : 0
						),
					show: true,
					width: 120,
					filterable: false
				},
				{
					Header: ls.value,
					id: "updateAmount",
					accessor: c => toStringCurrency(c?.amount / 100),
					show: true,
					width: 120,
					filterable: false,
					Footer: (
						<div style={{ fontWeight: "bold" }}>
							{toStringCurrency(value / 100)}
						</div>
					)
				},
				{
					Header: ls.paymentType,
					id: "type",
					accessor: c => ls[bankBillet[c.type]],
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter?.value}
						>
							<option value="">Todos</option>
							{Object.keys(bankBillet).map(c => (
								<option key={c} value={c}>
									{ls[bankBillet[c]]}
								</option>
							))}
						</select>
					),
					show: true,
					width: 160
				},
				{
					Header: ls.actions,
					id: "actions",
					accessor: c => (
						<Grid container spacing={2} justify="center">
							<Grid item>
								{c.status === 2 ? (
									<IconButton
										style={{
											padding: 8,
											fontSize: "1.3rem"
										}}
										onClick={() => {
											setItem(c);
											handleDownloadPDF();
										}}
									>
										<MdPictureAsPdf />
									</IconButton>
								) : (
									<></>
								)}
							</Grid>
						</Grid>
					),
					show: true,
					width: 100,
					filterable: false
				}
			].filter(c => c),

		[]
	);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBusinessDebounced) {
			getBusinessDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate?.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBusinessDebounced = _.debounce(
			() =>
				dispatch(
					getTickets(
						page * pageSize,
						pageSize,
						filtered
							.filter(i => i.value)
							.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getBusinessDebounced();
	};

	useEffect(() => {
		if (table.current) {
			let { page, pageSize, sorted, filtered } = table.current.state;

			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			if (!sorted) sorted = [];

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getTickets(
							page * pageSize,
							pageSize,
							filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
							sorted[0]?.id,
							sorted[0]?.desc
						)
					),
				500
			);

			getBusinessDebounced();
		}
	}, [dispatch]);

	return (
		<div>
			<Helmet>
				<title>Boletos</title>
			</Helmet>
			<Report
				manual
				title="Boletos"
				data={items}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				loading={loading}
				columns={columns}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			<div style={{ position: "absolute", left: -9999 }}>
				<PDFExport
					ref={pdfExportComponent}
					paperSize="A4"
					fileName={`comprovante_pagamento_boleto_${new Date().getMilliseconds()}`}
				>
					<TicketTemplatePDF item={item} />
				</PDFExport>
			</div>
		</div>
	);
}

export default TicketWiipoFlex;
