import ls from "Localization";

export const typesTransaction = {
	"0": ls.credit,
	"1": ls.debt,
	"2": ls.creditBetweenTreasuries,
	"3": ls.debitBetweenTreasuries
};

export const statusTransaction = {
	"0": ls.pending,
	"1": ls.completed,
	"2": ls.canceled,
	"3": ls.schedule,
	"4": ls.partialApproved,
	"5": ls.processing,
	"6": ls.duplicated,
	"7": ls.preProcessing
};
