import {
	Button,
	CircularProgress,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider
} from "@material-ui/core";
import React from "react";

import useStyles from "./styles";
import { DialogProps } from "./types";

const HelpiiDesactivateDialog: React.FC<DialogProps> = ({
	open,
	onClose,
	handleStatusChange,
	loading
}) => {
	const styles = useStyles({});
	return (
		<MuiDialog open={open} onClose={onClose} classes={{ paper: styles.dialog }}>
			<DialogTitle className={styles.title}>
				Desativação temporária do Helpii
			</DialogTitle>
			<DialogContent
				style={{
					textAlign: "center"
				}}
			>
				Todas as funcionalidades e notificações associadas a este serviço serão
				suspensas. Você poderá reativar o serviço a qualquer momento.
			</DialogContent>
			<Divider />
			<DialogActions
				style={{
					paddingRight: 24
				}}
			>
				<Button
					onClick={onClose}
					color="primary"
					variant="text"
					disabled={loading}
					style={{
						textTransform: "initial",
						fontSize: 14,
						fontWeight: "bold"
					}}
				>
					Cancelar
				</Button>
				<Button
					onClick={handleStatusChange}
					color="primary"
					variant="contained"
					disabled={loading}
					disableElevation
					style={{
						borderRadius: 40,
						textTransform: "initial",
						fontSize: 14,
						fontWeight: "bold"
					}}
				>
					{loading ? <CircularProgress size={20} /> : "Desativar"}
				</Button>
			</DialogActions>
		</MuiDialog>
	);
};

export default HelpiiDesactivateDialog;
