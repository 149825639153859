import history from "AppHistory";
import { toastError, toastSuccess } from "helpers/toast";
import _ from "lodash";
import LogRocket from "logrocket";

import { getErrors } from "../../../helpers/error";
import ls from "../../../Localization";
import * as api from "../../../services/api";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN = "LOGIN";

export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILED = "PASSWORD_RECOVERY_FAILED";

export const SET_USER_DATA = "[USER] SET DATA";
export const GET_USER_DATA = "[USER] GET DATA";
export const GET_USER_DATA_ERROR = "[USER] GET DATA ERROR";

export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const SET_ERROR = "[USER] SER_ERROR";

export const SET_VALUE = "[LOGIN] SET_VALUE";
export const CLEAR_VALUES = "[LOGIN] CLEAR_VALUES";

export const STOP_LOADING = "[LOGIN] STOP_LOADING";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitRecoverPassword() {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: PASSWORD_RECOVERY });

			const { recoverEmail, captcha, trustUserAgentClient } = getState().login;

			const response = await api.sendPost(
				"/password/recover",
				{
					email: recoverEmail,
					method: 0,
					captcha,
					trustUserAgentClient
				},
				{ "x-mode": "control" } // SRE: secret header for allowing access on WAF
			);
			const json = await response.json();
			if (response.ok && json.isValid) {
				dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

				dispatch(
					setValue({
						username: recoverEmail,
						phone: json.model,
						showCodeInput: true,
						...(json.params || {})
					})
				);
			} else if (json.errors.default.includes("User is null")) {
				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: "E-mail inválido" }
				});
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: error }
				});
			}
		} catch (e) {
			console.log(e);

			let error = "Ocorreu um erro";

			dispatch({
				type: PASSWORD_RECOVERY_FAILED,
				payload: { recoverEmail: error }
			});
		}
	};
}

export function confirmRecoverToken() {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: PASSWORD_RECOVERY });

			const { code, recoverEmail, captcha } = getState().login;

			const response = await api.sendPost("/password/recover/confirm", {
				email: recoverEmail,
				code,
				captcha
			});

			if (response.status === 200) {
				try {
					const json = await response?.json();

					if (json.isValid && json.model === code) {
						dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

						history.push({
							pathname: "/ChangePassword"
						});
					}
				} catch (err) {
					let error = "Token inválido ou expirado";

					dispatch({
						type: PASSWORD_RECOVERY_FAILED,
						payload: { code: error }
					});
				}
			} else {
				let error = "Token expirado";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { code: error }
				});
			}
		} catch (e) {
			console.log(e);
			let error = "Ocorreu um erro";

			dispatch({
				type: PASSWORD_RECOVERY_FAILED,
				payload: { code: error }
			});
		}
	};
}

export function handleChangePassword() {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: PASSWORD_RECOVERY });

			const { code, recoverEmail, password, confirmPassword } =
				getState().login;

			const response = await api.sendPut(`/Account/ChangePassword/${code}`, {
				document: recoverEmail,
				newPassword: password,
				confirmNewPassword: confirmPassword
			});

			// const json = await response.json();

			if (response.ok) {
				dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

				dispatch(
					setValue({
						username: recoverEmail,
						message: "Senha alterada com sucesso"
					})
				);

				history.push({
					pathname: "/Home"
				});
			} else {
				if (response.headers.get("content-type").indexOf("json") > -1) {
					const json = await response.json();

					dispatch({
						type: PASSWORD_RECOVERY_FAILED,
						payload: { password: json.errors.default[0] }
					});

					return;
				}

				let error = "Ocorreu um erro";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { password: error }
				});
			}
		} catch (e) {
			console.log(e);

			let error = "Ocorreu um erro";

			dispatch({
				type: PASSWORD_RECOVERY_FAILED,
				payload: { password: error }
			});
		}
	};
}

export function handleHttpResponse(response) {
	if (response.ok) return response.json();

	if (response.status === 401) {
		throw new Error("Http Response is Unauthorized");
	}

	throw new Error("Http Response is not ok");
}

export function handleHttpError(error) {
	return dispatch => {
		if (error.message === "Http Response is Unauthorized") {
			dispatch(logoutUser());
		}

		dispatch({
			type: "ERROR"
		});
	};
}

export function logoutUser() {
	return {
		type: USER_LOGGED_OUT
	};
}

let refreshTokenPromise = null;
export function refreshToken(dispatch, getState) {
	let {
		login: { token, user }
	} = getState();
	dispatch(logoutUser());
	/* try { */
	/* if (refreshTokenPromise) return refreshTokenPromise; */
	/*
		refreshTokenPromise = api
			.sendPost("/refreshToken", {
				token,
				userId: user.id
			})
			.then(handleHttpResponse)
			.then(json => {
				if (json.isValid && json.model) {
					let token = json.model;

					dispatch({
						type: LOGIN_SUCCESS,
						payload: token
					});

					dispatch({
						type: REFRESH_TOKEN_SUCCESS
					});

					return Promise.resolve(token);
				}

				const error = {
					username: json.errors.includes("emptyEmail")
						? "emptyEmail"
						: json.errors.includes("Account disabled.")
						? "Account disabled."
						: null,
					password: json.errors.includes("Invalid password.")
						? "Invalid password."
						: null
				};

				dispatch({
					type: REFRESH_TOKEN_ERROR,
					payload: error
				});

				return Promise.reject(error);
			})
			.catch(err => {
				dispatch(handleHttpError(err)); */

	/* 				return Promise.reject(err);
			})
			.finally(() => {
				refreshTokenPromise = null;
			});
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_ERROR,
			payload: {
				error: "Connection error",
				exception: error
			}
		});

		dispatch(logoutUser());

		return Promise.reject(error);
	}

	return refreshTokenPromise; */
}

export function getUserData(callback?) {
	return async (dispatch, getState) => {
		const { token, loading } = getState().login;

		if (loading.user) {
			return;
		}

		dispatch({
			type: GET_USER_DATA
		});

		try {
			let response = await api.sendGet("/user/me", {
				Authorization: `Bearer ${token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SET_USER_DATA,
					payload: result.model
				});

				if (process.env.REACT_APP_LOG_ROCKET)
					LogRocket.identify(result.model.id, {
						name: result.model.fullName,
						email: result.model.email
					});

				callback && callback();
			} else {
				dispatch({
					type: GET_USER_DATA_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_DATA_ERROR,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export async function resendCode(userId, approvalMethod) {
	try {
		let response = await api.sendPost("/code/resend", {
			userId,
			approvalMethod
		});
		let result = await response.json();
		if (result.isValid) {
			toastSuccess("Código reenviado com sucesso");
		}
	} catch (error) {
		toastError("Erro ao reenviar código");
	}
}

export function confirmCode(userId, code, trustUserAgentClient, callback?) {
	return async dispatch => {
		dispatch({
			type: LOGIN
		});

		let params = "";
		if (trustUserAgentClient) {
			params += `?trustUserAgentClient=${trustUserAgentClient}`;
		}

		try {
			let response = await api.sendPost(`/code/confirm${params}`, {
				userId,
				code
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: result.model
				});

				dispatch(getUserData(callback));
			} else {
				callback(null, getErrors(result.errors));
				dispatch({
					type: LOGIN_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			callback(null, error);
			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function submitLogin(callback?, approvalMethod?) {
	return async (dispatch, getState) => {
		dispatch({
			type: LOGIN
		});

		const { username, password, trustUserAgentClient } = getState().login;

		const errors: any = {};

		if (!username) errors.username = ls.required;
		if (!password) errors.password = ls.required;
		else if (password.length < 6) errors.password = ls.invalid;

		dispatch({
			type: SET_ERROR,
			payload: errors
		});

		if (_.keys(errors).length) return;
		let params = "";

		if (approvalMethod) {
			params += `&approvalMethod=${approvalMethod}`;
		}
		if (trustUserAgentClient) {
			params += `&trustUserAgentClient=${trustUserAgentClient}`;
		}

		try {
			let response = await api.sendPost(
				`/token/dashboard?local=dashboard${params}`,
				{
					username,
					password
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (!result.model?.token) {
					dispatch({ type: STOP_LOADING });
					callback(result.model);
				} else {
					dispatch({
						type: LOGIN_SUCCESS,
						payload: result.model
					});

					dispatch(getUserData(callback));
				}
			} else {
				dispatch({
					type: LOGIN_ERROR,
					payload: getErrors(result.errors)
				});
				callback(null, getErrors(result.errors));
			}
		} catch (error) {
			callback(null, error);
			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function submitLoginByPlatformWiipo(data) {
	return async (dispatch, _) => {
		if (data?.model) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: data.model
			});

			dispatch(getUserData());
		}
	};
}
