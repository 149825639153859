import {
	Button,
	CircularProgress,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider
} from "@material-ui/core";
import React from "react";

import useStyles from "./styles";
import { DialogProps } from "./types";

const HelpiiActivatedDialog: React.FC<DialogProps> = ({ open, onClose }) => {
	const styles = useStyles({});
	return (
		<MuiDialog open={open} onClose={onClose} classes={{ paper: styles.dialog }}>
			<DialogTitle className={styles.title}>
				Helpii ativado com sucesso
			</DialogTitle>
			<DialogContent
				style={{
					textAlign: "center"
				}}
			>
				O Helpii foi ativado com sucesso. Todas as funcionalidades e
				notificações associadas ao serviço estão agora ativas e disponíveis.
			</DialogContent>
			<Divider />
			<DialogActions
				style={{
					paddingRight: 24
				}}
			>
				<Button
					onClick={onClose}
					color="primary"
					variant="contained"
					disableElevation
					style={{
						borderRadius: 40,
						textTransform: "initial",
						fontSize: 14,
						fontWeight: "bold"
					}}
				>
					Fechar
				</Button>
			</DialogActions>
		</MuiDialog>
	);
};

export default HelpiiActivatedDialog;
