import { Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import { isInRole } from "helpers/auth";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/business/detail";
import { getBusinesses } from "store/actions/business/report";

import AddFranchiseBusiness from "./AddFranchiseBusiness";
import styles from "./styles";

let getBusinessDebounced: (() => void) & _.Cancelable = null;

function FranchiseBusinesses() {
	const dispatch = useDispatch();

	const table = useRef(null);

	const [showAdd, setShowAdd] = useState(false);

	const [items, setItems] = useState([]);
	const [pages, setPages] = useState(1);
	const [loading, setLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const { item } = useSelector<any, any>(s => s.businessDetail);

	const { user } = useSelector<any, any>(s => s.login);

	const isAdmin = isInRole(user, ["admin"]);

	const handleClose = reload => {
		if (reload) load();

		setShowAdd(false);
	};

	const handleRemoveFranchiseBusiness = businessId => {
		dispatch(
			actions.addFranchiseBusiness("", businessId, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					load();
				}
			})
		);
	};

	const handleFetchData = useCallback(() => {
		if (!item || !isLoaded) return;

		if (getBusinessDebounced) {
			getBusinessDebounced.cancel();
		}

		getBusinessDebounced = _.debounce(load, 500);

		getBusinessDebounced();
	}, [item, isLoaded]);

	const load = useCallback(() => {
		if (!table.current) return;

		setLoading(true);

		let { page, pageSize, sorted, filtered, toExport, callback } =
			table.current.state;

		if (!sorted) sorted = [];

		dispatch(
			getBusinesses(
				page * pageSize,
				pageSize,
				filtered.reduce(
					(p, c) => `${p}&filters[${c.id}]=${c.value}`,
					`&filters[franchiseId]=${item.id}`
				),
				sorted[0]?.id,
				sorted[0]?.desc,
				toExport,
				(err, models, params) => {
					if (!err) {
						setItems(models);
						setPages(Math.ceil(params.count / pageSize));
					}

					callback && callback(err, models);

					setLoading(false);

					if (!isLoaded) setIsLoaded(true);
				},
				false,
				true
			)
		);
	}, [dispatch, item, isLoaded, table.current]);

	const columns = useMemo(() => {
		const columns = [
			{
				Header: ls.name,
				id: "name",
				accessor: c => c.name,
				defaultSortDesc: true,
				show: true,
				width: 250
			},
			{
				Header: ls.phoneNumber,
				id: "phoneNumber",
				accessor: c => c.phoneNumber,
				defaultSortDesc: true,
				width: 170,
				show: true
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				show: true,
				width: 170,
				accessor: c => c.cnpj
			},
			{
				Header: ls.address,
				id: "address",
				show: true,
				accessor: c => c.address?.fullAddress
			},
			{
				Header: ls.actions,
				id: "actions",
				show: true,
				width: 100,
				accessor: c => (
					<>
						{isAdmin && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									flex: 1,
									cursor: "pointer"
								}}
								title="Remover filial"
							>
								<IconButton
									style={{
										padding: 8
									}}
									onClick={() => handleRemoveFranchiseBusiness(c.id)}
								>
									<MdDelete color="red" />
								</IconButton>
							</div>
						)}
					</>
				)
			}
		];

		return columns;
	}, []);

	if (!item) return null;

	return (
		<div>
			{isAdmin && (
				<AddFranchiseBusiness open={showAdd} handleClose={handleClose} />
			)}
			<LoadOnScroll load={load}>
				<Report
					manual
					tableRef={table}
					title={ls.franchiseBusinesses}
					data={items || []}
					pages={pages}
					loading={loading}
					onFetchData={handleFetchData}
					filterable
					visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
					headerRightComponent={
						<>
							{isAdmin && (
								<div>
									<Button color="primary" onClick={() => setShowAdd(true)}>
										{ls.addFranchiseBusiness}
									</Button>
								</div>
							)}
						</>
					}
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={columns}
					onRowClicked={row => {
						const isStore = [1, 3].includes(row.type);

						const path = isStore ? "store" : "company";

						window.open(`/${path}/${row.id}`, "_blank");
					}}
					defaultSorted={[
						{
							id: "creationDate",
							desc: true
						}
					]}
				/>
			</LoadOnScroll>
			{isAdmin && (
				<AddFranchiseBusiness open={showAdd} handleClose={handleClose} />
			)}
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(FranchiseBusinesses);
