import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		margin: 0,
		padding: 0
	},
	container: {
		width: "100%",
		height: "100%"
	},
	textField: {},
	card: {
		maxWidth: 440,
		padding: "1rem"
	},
	logoContainer: {
		padding: ".75rem 1.25rem",
		paddingTop: "1.5rem"
	},
	submitButton: {
		width: "100%",
		backgroundColor: "#00ca6e",
		color: "white",
		padding: ".84rem 2.14rem",
		paddingTop: ".7rem",
		paddingBottom: ".7rem",
		borderRadius: ".125rem",
		textTransform: "uppercase",
		marginBottom: "0.5rem",
		marginTop: "1.5rem",
		textAlign: "center",
		"&:disabled": {
			backgroundColor: "#2bbbad",
			color: "#f3f3f3"
		},
		"&:hover": {
			backgroundColor: "#01a95c"
		}
	},
	newAccount: {
		marginTop: "2rem",
		textAlign: "center"
	},
	mr5: {
		marginRight: 5
	},
	"@global": {
		body: {
			height: "100%",
			backgroundColor: "#f5f5f5"
		},
		html: {
			height: "100%"
		},
		"#root": {
			height: "100%"
		}
	},
	alertContainer: {
		position: "absolute",
		top: 45
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	platformWiipoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%"
	}
}));

export default useStyles;
