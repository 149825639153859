import "react-table/react-table.css";

import {
	Badge,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	ClickAwayListener,
	Grid,
	Grow,
	IconButton,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography
} from "@material-ui/core";
import { PDFExport } from "@progress/kendo-react-pdf";
import history from "AppHistory";
import TooltipLight from "Components/TooltipLight";
import ls from "Localization";
import queryString from "query-string";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { BsFiletypeCsv, BsFiletypeXlsx } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Column, Filter } from "react-table";
import { setValue } from "store/actions/component/report";
import ModalClientMail from "views/Dashboard/Transaction/Detail/ModalClientMail";

import { exportExcel, exportPDF } from "./export";
import ModalTrash from "./ModalTrash";
import { Props } from "./Report.types";
import useStyles, { StyledReactTable } from "./styles";

function Report({
	title,
	componentTitle,
	data,
	columns,
	qtdProduct,
	tableRef,
	headerRightComponent,
	onRowClicked,
	showExport,
	showEmail,
	showTrash,
	// showPDF,
	sendMailAll,
	// downloadPDFAll,
	clientEmail,
	saleProps,
	visibleColumns,
	hasBadge,
	badgeIcon,
	badgeContent,
	badgeColor,
	badgeStyle,
	badgeInfo,
	badgeInfoStyle,
	badgeHasLink,
	badgeLink,
	styledCard,
	useQueryString,
	enableCheckBox,
	handleChangeModalTrash,
	openModalTrash,
	isPaginationManual,
	defaulSizePages,
	pages,
	onFetchData,
	onRowClickedCheckBox,
	handleSelectAll,
	tableRowHeight,
	handleExportExternally,
	showExportPDF,
	templatePDF,
	...rest
}: Props) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [showColumns, setShowColumns] = useState(
		visibleColumns || columns.map(c => c.Header.toString())
	);

	const [showColumnsMenu, setShowColumnsMenu] = useState(false);
	const columnsMenuAnchor = useRef(null);
	const [rColumns, setRColumns] = useState(columns);
	const [exportComponent, setExport] = useState(null);
	const table = useRef(null);
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [selectAll, setSelectAll] = useState(false);

	const handleExcluded = useCallback(() => {}, []);

	const handleSendMailAll = useCallback(
		({ id, saleId, email }) => {
			sendMailAll({ id, saleId, email });
		},
		[sendMailAll]
	);

	const lengthData = useMemo(
		() =>
			data && data?.length > 0
				? data?.filter(i => i?.checked === true)?.length
				: 0,
		[data]
	);

	const nameBusiness = useMemo(
		() => (data && data[0]?.business?.business?.socialName) || "",
		[data]
	);

	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * defaulSizePages;
	const endIndex = startIndex + defaulSizePages;

	const itemsToShow = useMemo(
		() => data?.slice(startIndex, endIndex) || [],
		[data, endIndex, startIndex]
	);

	const handlePageChange = newPage => {
		if (newPage >= 0 && newPage <= pages) {
			setCurrentPage(newPage + 1);
		}
	};

	const pdfExportComponent = useRef(null);

	const handleDownloadPDF = () => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	};

	const selectAllItems = useCallback(
		(select: boolean) => {
			setSelectAll(select);
			handleSelectAll(select);
		},
		[handleSelectAll]
	);

	const { search } = window.location;

	const defaultFiltered = useMemo(() => {
		const filters = rest.defaultFiltered || [];

		if (!useQueryString) return filters;

		const query = queryString.parse(search) as Record<string, string>;

		for (const key in query) {
			if (Object.prototype.hasOwnProperty.call(query, key)) {
				const filter = filters.find(c => c.id === key);

				let value = decodeURIComponent(query[key]);

				if (["undefined", "null"].includes(value)) value = "";

				if (value.startsWith("{")) value = JSON.parse(value);

				if (filter) filter.value = value;
				else
					filters.push({
						id: key,
						value
					});
			}
		}

		return filters;
	}, [rest.defaultFiltered, search, useQueryString]);

	useEffect(() => {
		for (const column of columns) {
			column.show =
				!showColumns.length ||
				showColumns.indexOf(column.Header.toString()) > -1;
		}

		if (enableCheckBox && onRowClickedCheckBox) {
			setRColumns([
				{
					Header: handleSelectAll && (
						<Checkbox
							id="selectAll"
							name="selectAll"
							checked={selectAll}
							onChange={() => {
								selectAllItems(!selectAll);
							}}
						/>
					),
					id: "selection",
					accessor: c => (
						<Checkbox
							id="selectRow"
							name="selectRow"
							checked={c.checked}
							style={{ padding: "0px 5px", marginBottom: 4 }}
							onChange={() => {
								onRowClickedCheckBox(c, !c.checked);
							}}
						/>
					),
					width: 50,
					sortable: false,
					filterable: false
				},
				...columns
			]);
		} else {
			setRColumns([...columns]);
		}
	}, [
		showColumns,
		columns,
		enableCheckBox,
		onRowClickedCheckBox,
		selectAll,
		handleSelectAll,
		data,
		selectAllItems,
		lengthData
	]);

	const handleShowColumnsChange = (c: Column<any>) => {
		setShowColumns(prev => {
			let value = c.Header.toString();
			if (prev.indexOf(value) > -1) return prev.filter(p => p !== value);

			prev.push(value);

			return [...prev];
		});
	};

	const handleListKeyDown = event => {
		if (event.key === "Tab") {
			event.preventDefault();
			setShowColumnsMenu(false);
		}
	};

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleExportToPDF = useCallback(async () => {
		const items = await exportPDF(tableRef || table, rColumns);

		handleChange("items", items);
		handleDownloadPDF();
	}, [handleChange, rColumns, tableRef]);

	const handleExport = useCallback(
		async type => {
			if (handleExportExternally) {
				const tableLocal = tableRef || table;
				const tableData = tableLocal.current.getResolvedState();

				return handleExportExternally(tableData);
			}

			exportExcel(tableRef || table, rColumns, title, setExport, type);
		},
		[handleExportExternally, rColumns, tableRef, title]
	);

	const handleOpenModal = () => {
		setShowModalAdd(true);
	};

	const renderHeaderRightComponent = useCallback(() => {
		if (headerRightComponent && !showExport) {
			if (!visibleColumns || !visibleColumns.length)
				return headerRightComponent;
		}

		return (
			<div
				style={{
					alignItems: "center",
					display: "flex"
				}}
			>
				{search && <Button onClick={cleanFilters}>Limpar filtros</Button>}
				{headerRightComponent}
				<div
					style={{
						marginRight: 10
					}}
				>
					{qtdProduct && `Qtd: ${qtdProduct}`}
				</div>
				{qtdProduct > 1 && (
					<>
						{/* {showPDF && (
							<>
								<TooltipLight title="Exportar PDF" placement="top">
									<IconButton
										style={{ padding: 4 }}
										onClick={() => {
											handleDownloadPDF({
												id: saleProps.id,
												saleId: saleProps.saleId
											});
										}}
									>
										<MdPictureAsPdf />
									</IconButton>
								</TooltipLight>
							</>
						)} */}
						{showEmail && (
							<>
								<TooltipLight title="Enviar e-mail" placement="top">
									<IconButton onClick={handleOpenModal}>
										<MdEmail />
									</IconButton>
								</TooltipLight>
								<ModalClientMail
									open={showModalAdd}
									onSubmitMail={({ email }) => {
										handleSendMailAll({
											id: saleProps.id,
											saleId: saleProps.saleId,
											email
										});
									}}
									emailDefault={clientEmail}
									handleClose={() => setShowModalAdd(false)}
								/>
							</>
						)}
					</>
				)}

				{showExport && (
					<>
						<TooltipLight title="Exportar tabela .xlsx" placement="top">
							<IconButton onClick={() => handleExport("xlsx")}>
								<BsFiletypeXlsx />
							</IconButton>
						</TooltipLight>
						<TooltipLight title="Exportar tabela .csv" placement="top">
							<IconButton onClick={() => handleExport("csv")}>
								<BsFiletypeCsv />
							</IconButton>
						</TooltipLight>
					</>
				)}

				{showExportPDF && (
					<TooltipLight title="Exportar tabela em PDF" placement="top">
						<IconButton onClick={handleExportToPDF}>
							<FaFilePdf />
						</IconButton>
					</TooltipLight>
				)}
				{showTrash && (
					<TooltipLight title="Excluir Usuários" placement="top">
						<IconButton
							onClick={handleChangeModalTrash}
							disabled={lengthData === 0}
						>
							<FaTrash size={20} />
							{lengthData ? `(${lengthData})` : ""}
						</IconButton>
					</TooltipLight>
				)}

				{openModalTrash && (
					<ModalTrash
						open={openModalTrash}
						handleClose={handleChangeModalTrash}
						length={lengthData}
						nameBusiness={nameBusiness}
						handleExcludeUsers={handleExcluded}
					/>
				)}

				<Button
					ref={columnsMenuAnchor}
					aria-controls={showColumnsMenu ? "column-menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={() => setShowColumnsMenu(prev => !prev)}
				>
					Colunas
				</Button>

				<Popper
					open={showColumnsMenu}
					style={{ zIndex: 1 }}
					anchorEl={columnsMenuAnchor.current}
					role={undefined}
					placement="bottom-end"
					transition
				>
					{({ TransitionProps }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: "right top"
							}}
						>
							<Paper
								style={{
									maxHeight: 400,
									overflowY: "auto"
								}}
							>
								<ClickAwayListener
									onClickAway={() => setShowColumnsMenu(false)}
								>
									<MenuList
										autoFocusItem={showColumnsMenu}
										id="column-menu-list-grow"
										onKeyDown={handleListKeyDown}
									>
										{columns.map((c, i) => (
											<MenuItem
												key={i}
												value={c.id}
												onClick={() => handleShowColumnsChange(c)}
											>
												<Checkbox
													checked={
														showColumns.indexOf(c.Header.toString()) > -1
													}
												/>
												<ListItemText primary={c.Header.toString()} />
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	}, [
		headerRightComponent,
		showExport,
		search,
		qtdProduct,
		showEmail,
		showModalAdd,
		clientEmail,
		handleExport,
		showExportPDF,
		handleExportToPDF,
		showTrash,
		handleChangeModalTrash,
		lengthData,
		openModalTrash,
		nameBusiness,
		handleExcluded,
		showColumnsMenu,
		visibleColumns,
		handleSendMailAll,
		saleProps?.id,
		saleProps?.saleId,
		columns,
		showColumns
	]);

	const cleanFilters = () => {
		history.push({
			pathname: window.location.pathname
		});
	};

	const onFilteredChange = useCallback(
		(newFiltering: Filter[]) => {
			if (!useQueryString) return;

			const filters: Record<string, string> = {};

			for (const filter of newFiltering) {
				let { id, value } = filter;

				if (typeof value === "object") {
					if (value.format) value = value.format();
					else {
						value = JSON.stringify(value);
					}
				}

				filters[id] = encodeURIComponent(value);
			}

			const search = queryString.stringify(filters);

			history.push({
				pathname: window.location.pathname,
				search
			});
		},
		[useQueryString]
	);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card style={styledCard}>
					<CardHeader
						style={styledCard}
						title={
							<Grid item container direction="row" alignItems="center">
								{title && (
									<div style={{ display: "flex", alignItems: "center" }}>
										<Typography variant="h6" style={{ marginRight: 15 }}>
											{title}
										</Typography>
										{componentTitle}
									</div>
								)}
								{hasBadge && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<Badge
											badgeContent={badgeContent}
											color={badgeColor}
											style={badgeStyle}
										>
											{badgeIcon}
										</Badge>
										{badgeHasLink ? (
											<Link to={badgeLink}>
												<Typography style={badgeInfoStyle}>
													{badgeInfo}
												</Typography>
											</Link>
										) : (
											<Typography style={badgeInfoStyle}>
												{badgeInfo}
											</Typography>
										)}
									</div>
								)}
							</Grid>
						}
						classes={{ action: classes.action }}
						action={renderHeaderRightComponent()}
					/>
					<CardContent className={classes.cardContent}>
						<StyledReactTable
							tableRowHeight={tableRowHeight}
							className="-striped -highlight"
							data={isPaginationManual ? itemsToShow : data}
							pages={pages}
							ref={tableRef || table}
							columns={rColumns}
							defaultPageSize={defaulSizePages || 10}
							pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
							nextText={ls.nextText}
							onPageChange={page => {
								isPaginationManual ? handlePageChange(page) : {};
							}}
							previousText={ls.previousText}
							pageText={ls.pageText}
							ofText={ls.ofText}
							rowsText={ls.rowsText}
							defaultFiltered={defaultFiltered}
							getTheadThProps={() => ({
								style: {
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}
							})}
							getTdProps={(state, rowInfo, column) => ({
								style: {
									display: "flex",
									alignItems: "center",
									cursor:
										onRowClicked && !(column && column.id === "actions")
											? "pointer"
											: undefined
								},
								onClick: (e, handleOriginal) => {
									if (rowInfo && !(column && column.id === "actions")) {
										if (onRowClicked && column.id !== "selection") {
											onRowClicked(rowInfo.original);
										} else {
											handleOriginal && handleOriginal(e);
										}
									}
								}
							})}
							onFetchData={isPaginationManual ? () => {} : onFetchData}
							{...rest}
							onFilteredChange={onFilteredChange}
						/>
					</CardContent>
				</Card>
				{exportComponent}
				{showExportPDF && (
					<div style={{ position: "absolute", left: -9999 }}>
						<PDFExport
							ref={pdfExportComponent}
							paperSize="A4"
							fileName={title}
							scale={0.8}
						>
							{templatePDF}
						</PDFExport>
					</div>
				)}
			</Grid>
		</Grid>
	);
}

export default Report;
