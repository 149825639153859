/* eslint-disable */
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import { toastError } from "helpers/toast";
import React, { ReactNode } from "react";
import ReactExport from "react-export-excel";
import * as XLSX from "xlsx";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

export const getExcelData = (columns, data) => {
	const dataExcel = [];

	for (const d of data) {
		let row = [];
		for (const c of columns) {
			if (c.id !== "actions") {
				const id = typeof c.accessor === "string" ? c.accessor : c.id;
				let value = "";

				if (typeof c.accessor === "function")
					value = c.accessor(d._original || d);
				else value = d[id];

				if (typeof value === "object" || c.format) {
					if (c.format) {
						value = c.format(d._original || d);
					} else {
						value = (d._original || d)[id];
					}
				}

				if (c.id === "updatedBy" || c.id === "createdBy") {
					value = d[id]?.fullName;
				}

				if (c.id === "firstTransfer" || c.id === "lastTransfer") {
					value = d[id]?.updateDate;
				}

				if (
					typeof value === "string" &&
					value.includes("R$") &&
					id !== "description"
				) {
					const valueReplace = Number(
						value.replace(/[^\d,]/g, "").replace(",", ".")
					);
					if (id === "valueOriginal") {
						row.push(valueReplace * 100);
					} else {
						row.push(valueReplace);
					}
				} else {
					row.push(value);
				}
			}
		}
		dataExcel.push(row);
	}

	return [
		{
			columns: columns.filter(c => c.id !== "actions").map(c => c.Header),
			data: dataExcel
		}
	];
};

export const exportExcelGenerate = (
	json,
	title,
	name_arquive,
	type = "xlsx"
) => {
	if (type === "csv") {
		const ws = XLSX.utils.json_to_sheet(json);
		const csvData = XLSX.utils.sheet_to_csv(ws);

		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute(
			"download",
			name_arquive + new Date().toISOString().slice(0, 10) + ".csv"
		);

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} else {
		const wsData = XLSX.utils.json_to_sheet(json);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, wsData, title);

		XLSX.writeFile(
			wb,
			name_arquive + new Date().toISOString().slice(0, 10) + ".xlsx"
		);
	}
};

export const exportExcelRechargeHeadquarter = (data: any[]) => {
	const filterNegativeValue = data
		.filter(i => i.missingValue < 0)
		.map(i => ({
			CNPJ: i.cnpj,
			Faltando: toStringCurrency((i.missingValue / 100) * -1)
		}));

	const filterPositiveValue = data
		.filter(i => i.missingValue > 0)
		.map(i => ({
			CNPJ: i.cnpj,
			Sobrando: toStringCurrency(i.missingValue / 100)
		}));

	const wsNegative = XLSX.utils.json_to_sheet(filterNegativeValue);
	const wsPositive = XLSX.utils.json_to_sheet(filterPositiveValue);
	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb, wsNegative, "Faltando Saldo");
	XLSX.utils.book_append_sheet(wb, wsPositive, "Sobrando Saldo");

	XLSX.writeFile(wb, "LogRecargas" + new Date() + ".xlsx");
};

const exportCsv = (
	data: { columns: string[]; data: any[] }[],
	title: string
) => {
	if (!data || data.length === 0) {
		console.error("No data available to export");
		return;
	}

	const csvRows: string[] = [];

	csvRows.push(data[0].columns.join(","));

	data[0].data.forEach((row: any[]) => {
		const formattedRow = row.map((value: any) => {
			if (value === null || value === undefined) {
				return "";
			}
			return `"${String(value).replace(/"/g, '""')}"`;
		});
		csvRows.push(formattedRow.join(","));
	});

	const csvData = csvRows.join("\n");
	const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	const url = URL.createObjectURL(blob);
	link.setAttribute("href", url);
	link.setAttribute(
		"download",
		`${title}_${dayjs().format("YYYY-MM-DD-kk-mm-ss")}.csv`
	);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const exportExcel = async (
	table: any,
	columns: any[],
	title: string,
	setExport: (node: React.ReactNode) => void,
	type: "xlsx" | "csv" = "csv"
) => {
	let resolvedState = table.current.getResolvedState();
	const data = table.current.getSortedData(resolvedState).sortedData;

	const { pages, pageSize, onFetchData, resolveData, manual } = resolvedState;

	const callback = (err, data) => {
		if (err) {
			toastError(err);
		} else {
			const dataSet = getExcelData(columns, data);

			if (dataSet) {
				if (type === "csv") {
					exportCsv(
						dataSet,
						`${title}_${dayjs().format("YYYY-MM-DD-kk-mm-ss")}`
					);
				} else {
					setExport(
						<ExcelFile
							filename={`${title}_${dayjs().format("YYYY-MM-DD-kk-mm-ss")}`}
							hideElement
						>
							<ExcelSheet dataSet={dataSet} name={title} />
						</ExcelFile>
					);
				}
			}

			setTimeout(() => {
				setExport(null);
			}, 1000);
		}
	};

	if (manual) {
		let size = pages * pageSize;
		let page = 0;
		const items = [];

		try {
			do {
				const [err, d]: any = await new Promise(res => {
					return onFetchData({
						...resolvedState,
						page,
						pageSize: size,
						toExport: true,
						callback: (err, d) => {
							res([err, d]);
						}
					});
				});

				if (err) throw err;
				items.push(...d);
				page = items.length / d.length;
				size = d.length;
			} while (size === 1000);
		} catch (error) {
			console.log(error);
		}

		callback(null, resolveData(items));
	} else {
		callback(null, data);
	}
};

export const exportPDF = async (table: any, columns: any[]) => {
	let resolvedState = table.current.getResolvedState();

	const data = table.current.getSortedData(resolvedState).sortedData;

	const { pages, pageSize, onFetchData, resolveData, manual } = resolvedState;

	const callback = (err, data) => {
		if (err) toastError(err);
		else {
			const dataSet = getExcelData(columns, data);

			if (dataSet) {
				return dataSet;
			}
		}
	};
	if (manual) {
		let size = pages * pageSize;
		let page = 0;

		const items = [];
		try {
			do {
				const [err, d]: any = await new Promise(res => {
					onFetchData({
						...resolvedState,
						page,
						pageSize: size,
						toExport: true,
						callback: (err, d) => {
							res([err, d]);
						}
					});
				});

				if (err) throw err;

				items.push(...d);

				page = items.length / d.length;
				size = d.length;
			} while (size === 1000);
		} catch (error) {
			console.log(error);
		}

		return callback(null, resolveData(items));
	} else {
		return callback(null, data);
	}
};
