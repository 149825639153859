import {
	Button,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Grow,
	IconButton,
	Link,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonCopyClipboard from "Components/ButtonCopyClipboard";
import { CPFCNPJ } from "Components/NumberFormat";
import PopoverComponent from "Components/Popover";
import UserDetailPopover from "Components/Popover/UserDetail";
import Report from "Components/Report";
import Tooltip from "Components/Tooltip";
import ModalExtract from "Components/views/Dashboard/User/Detail/ModalExtract";
import dayjs from "dayjs";
import { isAnyAdmin, isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import {
	toastError,
	toastHTML,
	toastSuccess,
	toastWarning
} from "helpers/toast";
import { validationDataUser } from "helpers/validators";
import ls from "Localization";
import businessStatus from "models/businessStatus";
import { ErrorsValidationUser } from "models/interfaces/components/user";
import saleStatus from "models/saleStatus";
import userImportedBy from "models/userImportedBy";
import userStatus from "models/userStatus";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { IoRefreshOutline } from "react-icons/io5";
import { MdDelete, MdMoreVert } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { editAllowanceUser } from "store/actions/allowance";
import { createCheckout } from "store/actions/checkout";
import { userCalcLimit } from "store/actions/user/calcLimit";
import * as actions from "store/actions/user/detail";
import * as actionsSwap from "store/actions/user/refreshSwap";
import { sendRemoveLockoutUser } from "store/actions/user/removeLockout";
import { sendUsersOnboarding } from "store/actions/user/report";

import ChangePassword from "../ChangePassword";
import EditUser from "../Edit";
import EditBenefits from "../EditBenefits";
import AccountHolder from "./AccountHolder";
import AccountHolderTransaction from "./AccountHolderTransaction";
import BalanceAccountTransferReport from "./BalanceAccountTransfer";
import ExtraPropertiesReport from "./ExtraProperties";
import styles, { GridButtonCopy } from "./styles";

interface IPropsLimit {
	credit: number;
	limitTotal: number;
	salary: any;
	totalCredit: number;
}

export function UserDetail({
	classes,
	state: { item, transactions, allowances, loading, limit, limitTotal, credit },
	clearValues,
	getUser,
	getUserLimit,
	getUserTransactions,
	getUserAllowances,
	createCheckout,
	editAllowanceUser,
	sendUsersOnboarding,
	sendRemoveLockoutUser,
	activeUser,
	login: { user },
	history,
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const [showEdit, setShowEdit] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [showEditBenefits, setShowEditBenefits] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [alertModal, setAlertModal] = useState(null);
	const [propsCalcLimit, setPropsCalcLimit] = useState<IPropsLimit>();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openModalLogs, setOpenModalLogs] = useState(false);

	const handleCloseModalLogs = useCallback(() => {
		setOpenModalLogs(false);
	}, []);

	const handlePopoverOpen = useCallback(event => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handlePopoverClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const menuRef = useRef(null);

	useEffect(() => {
		dispatch(
			userCalcLimit(id, (err, model) => {
				if (err) {
					toastError(err);
				} else {
					setPropsCalcLimit(model);
				}
			})
		);
	}, [dispatch, id]);

	const handleSendSwap = useCallback(() => {
		dispatch(
			actionsSwap.sendUserToSwap(id, err => {
				if (err) {
					if (typeof err.default === "string") {
						toastError(`Error ao enviar para a swap : ${err.default}`);
					} else {
						const errors = Object.keys(err)
							.filter(i => i !== "_original")
							.map(j => `${j} : ${err[j].replace("[", "").replace("]", "")}`);

						if (errors.length > 0) {
							toastError(errors[0]);
						} else {
							toastError(err);
						}
					}
				} else {
					toastSuccess("Enviado com sucesso para a swap");
				}
			})
		);
	}, [dispatch, id]);

	const transactionColumns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					// if (desc) return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? -1 : 1;

					// eslint-disable-next-line no-nested-ternary
					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				}
			},
			{
				Header: ls.code,
				id: "code",
				accessor: c => c.code,
				width: 80
			},
			{
				Header: ls.store,
				id: "store",
				accessor: c => c.store?.name ?? ls.none
			},
			{
				Header: ls.value,
				id: "value",
				accessor: c => toStringCurrency(c.value / 100),
				Footer: c => (
					<b>
						{toStringCurrency(
							c.data.reduce((a, b) => a + b._original.value, 0) / 100
						)}
					</b>
				),
				width: 140
			},
			{
				Header: ls.discount,
				id: "discount",
				accessor: c => toStringCurrency(c.discount / 100),
				Footer: c => (
					<b>
						{toStringCurrency(
							c.data.reduce((a, b) => a + b._original.discount, 0) / 100
						)}
					</b>
				),
				width: 140
			},
			{
				Header: ls.finalValue,
				id: "finalValue",
				accessor: c => toStringCurrency((c.value - c.discount) / 100),
				Footer: c => (
					<b>
						{toStringCurrency(
							c.data.reduce(
								(a, b) => a + b._original.value - b._original.discount,
								0
							) / 100
						)}
					</b>
				),
				width: 140
			},
			{
				Header: ls.installments,
				id: "installmentAmount",
				accessor: c => c.installmentAmount,
				width: 140
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => ls[saleStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(saleStatus).map(c => (
							<option key={c} value={c}>
								{ls[saleStatus[c]]}
							</option>
						))}
					</select>
				),
				width: 140
			}
		],
		[]
	);

	useEffect(() => {
		clearValues();
	}, [clearValues]);
	const load = useCallback(() => {
		getUser(id);
	}, [id]);

	useEffect(() => {
		getUser(id, (err, model) => {
			if (err) {
				toastError(err);
			} else {
				let errors: ErrorsValidationUser[] = [];
				console.log(model);
				errors = validationDataUser(model);
				if (errors.length > 0) {
					toastHTML(
						<>
							{errors.map(i => (
								<p>
									{i.inputError} {i.error}
								</p>
							))}
						</>,
						null,
						5000,
						true
					);
				}
			}
		});
		getUserLimit(id);
		getUserTransactions(id);
		getUserAllowances(id);
	}, [getUser, getUserAllowances, getUserTransactions, getUserLimit, id]);

	const handleActiveUser = () => {
		activeUser(id, err => {
			if (err) console.log(err);
			else getUser(id);
		});
	};

	const handleCloseEdit = () => {
		getUser(id);
		setShowEdit(false);
	};

	const handleCloseEditBenefits = () => {
		getUser(id);
		getUserLimit(id);
		setShowEditBenefits(false);
	};

	const handleGenerateCheckoutConfirm = last => {
		createCheckout(id, last, err => {
			if (err) toastWarning(err);
			else {
				setAlertModal(null);
				history.push("/Checkout");
			}
		});
	};

	const handleConfirmRemoveLockout = user => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja remover Lockout do usuario?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação o usuario será desbloqueado.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleSendRemoveLockout()}
						color="primary"
						autoFocus
					>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleConfirmSendOnboarding = () => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja enviar a notificação de onboarding?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação uma mensagem de onboarding será enviada ao
						usuário.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleSendOnboarding()}
						color="primary"
						autoFocus
					>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleSendOnboarding = () => {
		sendUsersOnboarding([id], err => {
			if (err) {
				toastWarning(err);
			} else {
				setAlertModal(null);
				toastWarning("Envio da mensagem de onboarding agendado com sucesso");
			}
		});
	};

	const handleSendRemoveLockout = () => {
		sendRemoveLockoutUser([id], err => {
			console.log(id);
			if (err) {
				toastWarning(err);
			} else {
				setAlertModal(null);
				toastSuccess("Usuario desbloqueado  com sucesso");
			}
		});
	};

	const handleGenerateCheckout = () => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja incluir o último período?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Selecione abaixo se deseja incluir as parcelas referentes ao último
						período que terminou no último dia 19
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleGenerateCheckoutConfirm(true)}
						color="primary"
					>
						Incluir último período
					</Button>
					<Button
						onClick={() => handleGenerateCheckoutConfirm(false)}
						color="primary"
						autoFocus
					>
						Sem último período
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleMenuClose = event => {
		if (menuRef.current && menuRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleMenuListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	const handleDeleteAllowance = user => {
		editAllowanceUser(user.id, 0, err => {
			if (err) {
				console.log(err);
				if (err.default) toastWarning(err.default);
				else if (typeof err === "string") toastWarning(err);
				else toastWarning(JSON.stringify(err));
			} else {
				setAlertModal(null);
				getUserAllowances(id);
			}
		});
	};

	const onClickEditBenefit = useCallback(() => {
		setShowEditBenefits(true);
	}, []);

	const showConfirmDeleteAllowance = user => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja confirmar a exclusão?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação da exclusão não será possível recuperar os dados.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleDeleteAllowance(user)}
						color="primary"
						autoFocus
					>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	if (!item) return null;

	const isAdmin = isInRole(user, ["admin"]);
	const isBusinessAdmin = isInRole(user, ["business admin"]);
	const isWiipoFlex = isInRole(user, ["Admin Wiipo Flex"]);
	const isWiipoClub = isInRole(user, ["Admin Wiipo Club"]);
	const userDetailIsAnyAdmin = isAnyAdmin(item);

	let {
		creationDate,
		name,
		lastName,
		cpf,
		email,
		phoneNumber,
		address,
		enroll,
		admissionDate,
		status,
		costCenter,
		birthDate,
		mothersName,
		accountHolderBalance,
		business,
		unlinkDate,
		importedBy,
		passwordExpirationDate
	} = item;

	const daysLeftToPasswordExpire = passwordExpirationDate
		? dayjs(passwordExpirationDate).diff(dayjs(), "day")
		: null;

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginRight: 5
							}}
						>
							<Typography variant="h6">{ls.userData}</Typography>
							{!item.accountHolderId && isAdmin && (
								<IconButton
									onClick={handleSendSwap}
									style={{
										background: "#5734DA",
										borderRadius: 4,
										width: 22,
										height: 22,
										marginLeft: 4
									}}
									size="small"
								>
									<IoRefreshOutline size={20} color="#ffffff" />
								</IconButton>
							)}
						</div>

						<div>
							{(isAdmin || isBusinessAdmin || isWiipoFlex) && (
								<Tooltip
									title={
										!isAdmin && business?.business?.hasHCMIntegrator
											? "Acesse o HCM para editar o usuário"
											: "Editar usuário"
									}
								>
									<Button
										color="primary"
										onClick={() => setShowEdit(true)}
										disabled={!isAdmin && business?.business?.hasHCMIntegrator}
									>
										{ls.edit}
									</Button>
								</Tooltip>
							)}

							{status !== 1 && (
								<Button color="primary" onClick={handleActiveUser}>
									{ls.activate}
								</Button>
							)}

							{(isAdmin || isBusinessAdmin) && (
								<>
									<IconButton
										ref={menuRef}
										aria-controls={openMenu ? "menu-list-grow" : undefined}
										aria-haspopup="true"
										onClick={() => setOpenMenu(p => !p)}
									>
										<MdMoreVert />
									</IconButton>
									<Popper
										open={openMenu}
										anchorEl={menuRef.current}
										role={undefined}
										transition
										disablePortal
										placement="bottom-end"
									>
										{({ TransitionProps, placement }) => (
											<Grow
												{...TransitionProps}
												style={{
													transformOrigin:
														placement === "bottom"
															? "right top"
															: "right bottom"
												}}
											>
												<Paper>
													<ClickAwayListener onClickAway={handleMenuClose}>
														<MenuList
															autoFocusItem={openMenu}
															id="menu-list-grow"
															onKeyDown={handleMenuListKeyDown}
														>
															{isAdmin && (
																<MenuItem
																	onClick={() => setShowChangePassword(true)}
																>
																	{ls.changePassword}
																</MenuItem>
															)}
															<MenuItem onClick={handleGenerateCheckout}>
																{ls.generateCheckout}
															</MenuItem>
															<MenuItem onClick={handleConfirmSendOnboarding}>
																{ls.sendOnboarding}
															</MenuItem>
															<MenuItem
																onClick={() => handleConfirmRemoveLockout(user)}
															>
																{ls.removerLockout}
															</MenuItem>
														</MenuList>
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Popper>
								</>
							)}
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<strong>{ls.creationDate}</strong>:{" "}
							{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.name}</strong>: {name}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.lastName}</strong>: {lastName || ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4} style={{ marginTop: 10 }}>
							<strong>{ls.email}</strong>: {email || ls.notDefined}
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
						>
							<strong>{ls.cpfcnpj}</strong>:{" "}
							{cpf ? (
								<CPFCNPJ
									displayType="text"
									value={cpf}
									style={{ marginLeft: 5 }}
								/>
							) : (
								ls.notDefined
							)}
							<ButtonCopyClipboard valueCopy={cpf} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} style={{ marginTop: 10 }}>
							<strong>{ls.phoneNumber}</strong>: {phoneNumber || ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.admissionDate}</strong>:{" "}
							{admissionDate
								? dayjs(admissionDate).format(ls.dateFormatShort)
								: ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.enroll}</strong>: {enroll || ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.costCenter}</strong>: {costCenter || ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.birthDate}</strong>:{" "}
							{birthDate
								? dayjs(String(birthDate).replace("T00", "T03")).format(
										ls.dateFormatShort
								  )
								: ls.notDefined}
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.company}</strong>:{" "}
							<Link
								style={{ cursor: "pointer" }}
								onClick={() =>
									window.open(`/Company/${business?.business?.id}`, "_blank")
								}
								underline="hover"
								color={
									business?.business?.status === 1 ? "primary" : "secondary"
								}
							>
								{business?.business
									? `${business?.business.name}`
									: ls.notDefined}
								{business?.business.status === 1
									? ""
									: ` (${ls[businessStatus[business?.business?.status]]})`}
							</Link>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<strong>Cadastro via:</strong>{" "}
							<span
								className={`${classes.userImportedBy} ${
									importedBy === 0
										? classes.userUndefinedImportedBy
										: classes.userDefinedImportedBy
								}`}
							>
								{ls[userImportedBy[importedBy]]}
							</span>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.mothersName}</strong>: {mothersName || ls.notDefined}
						</Grid>
						<GridButtonCopy item xs={12} sm={6} md={4}>
							<strong>{ls.treasuryAccountID}</strong>:{" "}
							{business?.business?.treasuryAccountHolderId || ls.notDefined}
							{business?.business?.treasuryAccountHolderId && (
								<ButtonCopyClipboard
									valueCopy={business?.business?.treasuryAccountHolderId}
								/>
							)}
						</GridButtonCopy>

						<Grid item xs={12}>
							<strong>{ls.address}</strong>:{" "}
							{address ? address.fullAddress : ls.notDefined}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.status}</strong>: {ls[userStatus[status]]}
							<Tooltip
								title={`Desvínculo: ${dayjs(unlinkDate).format(
									ls.dateFormatWithoutSeconds
								)}`}
							>
								{status === 3 && accountHolderBalance > 0 ? (
									<FaMoneyCheckAlt size={20} className={classes.unlinkedUser} />
								) : null}
							</Tooltip>
						</Grid>
						{isAdmin && userDetailIsAnyAdmin && (
							<Grid item xs={12}>
								<strong>{ls.passwordExpirationDate}</strong>:{" "}
								{`${dayjs(passwordExpirationDate).format("DD/MM/YYYY")} - ${
									(daysLeftToPasswordExpire <= 0 && "Senha expirada") ||
									(daysLeftToPasswordExpire === 1 &&
										`${daysLeftToPasswordExpire} dia`) ||
									(daysLeftToPasswordExpire > 1 &&
										`${daysLeftToPasswordExpire} dias`)
								}`}
							</Grid>
						)}
					</Grid>
				</Paper>

				<EditUser
					open={showEdit}
					hasHCMIntegrator={business?.hasHCMIntegrator}
					item={item}
					handleClose={handleCloseEdit}
				/>

				<ChangePassword
					open={showChangePassword}
					item={item}
					handleClose={() => setShowChangePassword(false)}
				/>
			</Grid>

			{(isAdmin || isBusinessAdmin) && (
				<Grid item xs={11}>
					<Paper className={classes.root}>
						<div className={classes.header}>
							<Typography variant="h6">{ls.benefits}</Typography>

							<div>
								{(isAdmin || isBusinessAdmin) && (
									<Button color="primary" onClick={onClickEditBenefit}>
										{ls.edit}
									</Button>
								)}
							</div>
						</div>
						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<div className={classes.row}>
									<strong>{ls.limit}</strong>:{" "}
									{toStringCurrency(
										(limitTotal ||
											(item.creditLimit ? item.creditLimit.limit : 0)) / 100.0
									)}
									{isBusinessAdmin &&
										propsCalcLimit &&
										propsCalcLimit.salary?.base > 0 && (
											<PopoverComponent
												anchorEl={anchorEl}
												handlePopoverOpen={handlePopoverOpen}
											>
												<UserDetailPopover
													handlePopoverClose={handlePopoverClose}
													propsLimit={propsCalcLimit}
												/>
											</PopoverComponent>
										)}
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<strong>{ls.renewalDay}</strong>:{" "}
								{item.creditLimit ? item.creditLimit.renewalDay : ""}
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={4}>
								<strong>{ls.limitUsed}</strong>:{" "}
								{toStringCurrency(
									![undefined, null].includes(limit)
										? Math.abs(
												(limit -
													((limitTotal || item.creditLimit?.limit) ?? 0)) /
													100.0
										  )
										: 0
								)}
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={4}>
								<strong>{ls.remainingLimit}</strong>:{" "}
								{toStringCurrency(
									![undefined, null].includes(limit) ? limit / 100 : 0
								)}
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={4}>
								<strong>{ls.remainingCredit}</strong>:{" "}
								{toStringCurrency(
									![undefined, null].includes(credit) ? credit / 100 : 0
								)}
							</Grid>
						</Grid>
					</Paper>

					<EditBenefits
						open={showEditBenefits}
						item={item.creditLimit}
						isAdmin={isAdmin}
						isBusinessAdmin={isBusinessAdmin}
						handleClose={handleCloseEditBenefits}
					/>
				</Grid>
			)}

			<Grid item xs={11}>
				<AccountHolder item={item} />
			</Grid>

			{(isAdmin || isWiipoFlex) && (
				<Grid item xs={11}>
					<AccountHolderTransaction
						isWiipoFlex={isWiipoFlex}
						id={id}
						user={item}
						load={load}
					/>
				</Grid>
			)}

			<Grid item xs={11}>
				{isAdmin && <BalanceAccountTransferReport id={id} />}
			</Grid>

			<Grid item xs={11}>
				{(isAdmin || isWiipoClub) && <ExtraPropertiesReport id={id} />}
			</Grid>
			{openModalLogs && (
				<ModalExtract
					id={id}
					open={openModalLogs}
					handleClose={handleCloseModalLogs}
					load={load}
				/>
			)}

			{(isAdmin || isWiipoClub) && (
				<Grid item xs={11}>
					<Report
						title={ls.allowancePolicies}
						data={allowances}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						columns={[
							{
								Header: ls.creationDate,
								id: "creationDate",
								accessor: c =>
									dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
								width: 150,
								sortMethod: (a, b) => {
									let aD = dayjs(a, ls.dateFormatWithoutSeconds);
									let bD = dayjs(b, ls.dateFormatWithoutSeconds);

									// if (desc) return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? -1 : 1;

									// eslint-disable-next-line no-nested-ternary
									return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
								}
							},
							{
								Header: ls.company,
								id: "allowance.company.displayName",
								accessor: c =>
									c.allowance && c.allowance.company
										? c.allowance.company.displayName
										: ls.notDefined
							},
							{
								Header: ls.name,
								id: "allowance.name",
								accessor: c =>
									c.allowance && c.allowance.name
										? c.allowance.name
										: ls.notDefined
							},
							{
								Header: ls.startAt,
								id: "allowance.startDate",
								accessor: c =>
									c.allowance && c.allowance.startDate
										? dayjs(c.allowance.startDate).format(
												ls.dateFormatWithoutSeconds
										  )
										: ls.notDefined,
								width: 150
							},
							{
								Header: ls.endAt,
								id: "allowance.endDate",
								accessor: c =>
									c.allowance && c.allowance.endDate
										? dayjs(c.allowance.endDate).format(
												ls.dateFormatWithoutSeconds
										  )
										: ls.notDefined,
								width: 150
							},
							isAdmin
								? {
										Header: ls.actions,
										id: "actions",
										accessor: c => (
											<Grid container spacing={2} justify="center">
												<Grid item>
													{c.status === 1 && (
														<IconButton
															style={{
																padding: 8,
																fontSize: "1.3rem"
															}}
															onClick={() => showConfirmDeleteAllowance(c)}
														>
															<MdDelete />
														</IconButton>
													)}
												</Grid>
											</Grid>
										),
										width: 100,
										filterable: false
								  }
								: undefined
						].filter(c => c)}
						pageSize={5}
						loading={loading.getTransactions}
						defaultSorted={[
							{
								id: "creationDate",
								desc: true
							}
						]}
					/>
					{alertModal}
				</Grid>
			)}

			<Grid item xs={11}>
				<Report
					title={ls.transactions}
					data={transactions}
					filterable
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={transactionColumns}
					loading={loading.getTransactions}
					defaultSorted={[
						{
							id: "creationDate",
							desc: true
						}
					]}
					onRowClicked={row => history.push(`/Transaction/${row.id}`)}
					headerRightComponent={
						isWiipoFlex ? (
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									flex: 1,
									cursor: "pointer"
								}}
								title="Baixar extrato completo"
							>
								<AiOutlineFileExcel
									size={28}
									onClick={() => {
										setOpenModalLogs(true);
									}}
								/>
							</div>
						) : (
							<></>
						)
					}
				/>
				{alertModal}
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ userDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions,
	createCheckout,
	editAllowanceUser,
	sendUsersOnboarding,
	sendRemoveLockoutUser
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UserDetail));
