import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	button: {
		textTransform: "lowercase"
	},
	dialog: {
		borderRadius: 16,
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
	},
	title: {
		fontWeight: "bold",
		fontSize: theme.typography.pxToRem(20),
		padding: theme.spacing(2),
		paddingBottom: 0
	}
}));

export default useStyles;
