export const ROLES = {
	admin: {
		name: "Admin"
	},
	business_admin: {
		name: "Business Admin"
	},
	business_admin_flex: {
		name: "Admin Wiipo Flex"
	}
};
