import { Button } from "@material-ui/core";
import TabsTable from "Components/TabsTable";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toastHTML } from "helpers/toast";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearValues } from "store/actions/treasuryAccountWiipoFlex/create";
import * as actions from "store/actions/treasuryAccountWiipoFlex/report";
import { ITreasuryAccountTransferRequest } from "store/actions/treasuryAccountWiipoFlex/report";

import Create from "../Create";
import TreasuryAccountWiipoFlexReportDeposit from "./ReportDeposit";
import TreasuryAccountWiipoFlexReportTransfer from "./ReportTransfer";
import useStyles from "./styles";

dayjs.extend(customParseFormat);

function TreasuryAccountWiipoFlexReport() {
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleGetTransfers = useCallback(
		(props: ITreasuryAccountTransferRequest) => {
			const { skip, take, filter, sort, desc, toExport, callback } = props;
			dispatch(
				actions.getTransactionFunds({
					skip,
					take,
					filter,
					sort,
					desc,
					toExport,
					callback
				})
			);
		},
		[dispatch]
	);
	const { user } = useSelector<any, any>(s => s.login);
	const isAdmin = isInRole(user, ["admin"]);
	const arrayTabs = useMemo(
		() => [
			{
				label: "Depósitos",
				table: () => (
					<TreasuryAccountWiipoFlexReportDeposit
						handleGetTransfers={handleGetTransfers}
					/>
				)
			},
			{
				label: "Extrato",
				table: () => <TreasuryAccountWiipoFlexReportTransfer />
			}
		],
		[handleGetTransfers]
	);

	const enableAlert = useCallback(() => {
		if (new Date() < new Date("2024-12-31")) {
			toastHTML(
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<span>
						Atenção, como não haverá expediente bancário entre os dias 28/12 e
						01/01 os boletos com carga prevista deverão ter o vencimento até o
						dia 27/12.
					</span>
					<br />
					<span>Operações via PIX estarão operando normalmente.</span>
					<Button
						variant="contained"
						style={{
							height: 20,
							width: 120,
							fontSize: 11,
							marginTop: 5,
							background: "#fff",
							color: "red"
						}}
						onClick={() => {}}
					>
						OK
					</Button>
				</div>,
				null,
				false,
				false
			);
			// toastError(
			// 	"Atenção, como não haverá expediente bancário entre os dias 28/12 e 01/01 os boletos com carga prevista deverão ter o vencimento até o dia 27/12."
			// );
		}
	}, []);

	useEffect(() => {
		enableAlert();
		handleGetTransfers({ callback: () => {} });
		dispatch(clearValues());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Create handleGetTransfers={handleGetTransfers} />
			<TabsTable
				tabs={
					isAdmin ? arrayTabs : arrayTabs.filter(i => i.label !== "Extrato")
				}
			/>
		</>
	);
}

export default TreasuryAccountWiipoFlexReport;
