import React from "react";

interface UserShort {
	fullName: string;
	name: string;
	lastName: string;
	cpf: string;
	status: number;
	email: string;
	businessName: string;
	headquartersName: string;
}

interface PaymentTransaction {
	amount: number;
	barcode: string;
	type: number;
	status: number;
	userId: string | null;
	user: UserShort | null;
	clientId: string;
	payee: string | null;
	payeeDocument: string | null;
	payer: string | null;
	payerDocument: string | null;
	insertedAt: string;
	confirmedAt: string | null;
	canceledAt: string | null;
	dueDate: string;
	settleDate: string;
	finalPaymentDate: string;
	updatedAmount: number;
	updatedAt: string;
	startHour: string;
	endHour: string;
	discountAmount: number;
	fineAmount: number;
	interestAmount: number;
	maxAmount: number;
	minAmount: number;
	issuer: string;
	receipt: string;
	authentication: string;
	errorReason: string;
	id: string;
	creationDate: string;
	updateDate: string;
}

export interface PaymentItem {
	name: string | undefined;
	barCode: string | undefined;
	cnpj: string | undefined;
	nameBenefit: string | undefined;
	discount: number | undefined;
	fees: number;
	totalCharges: number | undefined;
	document: string | undefined;
	bag: string;
	cardNumber: string;
	autenticNumber: string;
	socialName: string;
	institution: string;
	value: number | undefined;
	paymentDay: Date | undefined;
	dueDate: Date | undefined;
	fine: number;
	valuePay: number | undefined;
}

interface PropsTemplate {
	item: PaymentTransaction;
}

const TicketTemplatePDF: React.FC<PropsTemplate> = ({ item }) => {
	const currentDate = new Date().toLocaleDateString();

	const styles: { [key: string]: React.CSSProperties } = {
		body: {
			display: "flex",
			flexDirection: "column",
			padding: "16px",
			margin: "0"
		},
		row: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			justifyContent: "space-between",
			fontSize: "16px",
			color: "#494949",
			marginBottom: 15
		},
		column: {
			display: "flex",
			flexDirection: "column",
			fontSize: "16px",
			color: "#494949"
		},
		title: {
			textAlign: "left",
			fontSize: "20px",
			fontWeight: "bold",
			color: "#494949",
			marginBottom: 5
		},
		titleHeader: {
			textAlign: "left",
			fontSize: "16px",
			fontWeight: "bold",
			color: "#7d7d7d",
			marginBottom: 20
		},
		subtitle: {
			textAlign: "left",
			fontSize: "14px",
			color: "#7D7D7D",
			marginBottom: 10
		},
		titleColumn: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			textAlign: "left",
			fontSize: "16px",
			color: "#494949",
			width: "100%",
			marginBottom: 20
		},
		value: {
			textAlign: "center",
			fontSize: "16px",
			color: "#494949"
		},
		copyButton: {
			fontSize: "16px",
			color: "#494949"
		},
		imgs: {
			marginBottom: 10
		},
		line: {
			background: "#c5c5c5",
			height: 2,
			marginTop: 30,
			marginBottom: 40
		}
	};

	return (
		<div style={styles.body}>
			<div style={styles.imgs}>
				<img
					height="40"
					width="70"
					src="/img/wiipo_color.png"
					alt="logo_wiipo"
				/>
			</div>

			<div style={styles.title}>Comprovante de pagamento</div>
			<div style={styles.subtitle}>{currentDate}</div>
			<div style={styles.row}>
				<p>Valor total:</p>
				<strong>
					{new Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL"
					}).format(item?.amount / 100 || 0)}
				</strong>
			</div>
			<div style={styles.row}>
				<p>Tipo de pagamento:</p>
				<strong>Boleto</strong>
			</div>

			<div style={styles.line} />
			<div style={styles.titleHeader}>Dados do pagante</div>

			<div style={styles.titleColumn}>
				Nome
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.user?.fullName} </div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				CPF
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.user?.cpf}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Número do cartão
				<div style={styles.column}>
					<div style={styles.copyButton}> </div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Sairá da categoria
				<div style={styles.column}>
					<div style={styles.copyButton}> Livre</div>
				</div>
			</div>

			<div style={styles.titleHeader}>Dados do pagamento</div>
			<div style={styles.titleColumn}>
				Instituição
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.payee}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Nome do beneficiário
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.payee}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Razão Social
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.issuer}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				CNPJ
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.payeeDocument}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Data do pagamento
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.finalPaymentDate}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Data de vencimento
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.dueDate}</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Valor do boleto
				<div style={styles.column}>
					<div style={styles.copyButton}>
						{new Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL"
						}).format(item?.amount / 100 || 0)}
					</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Desconto
				<div style={styles.column}>
					<div style={styles.copyButton}>
						{new Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL"
						}).format(item?.interestAmount / 100 || 0)}
					</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Juros/Mora
				<div style={styles.column}>
					<div style={styles.copyButton}>R$0,00</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Multa
				<div style={styles.column}>
					<div style={styles.copyButton}>R$0,00</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Total dos encargos:
				<div style={styles.column}>
					<div style={styles.copyButton}>
						{new Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL"
						}).format(item?.interestAmount / 100 || 0)}
					</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Valor Pago
				<div style={styles.column}>
					<div style={styles.copyButton}>
						{new Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL"
						}).format(item?.maxAmount / 100 || 0)}
					</div>
				</div>
			</div>
			<div style={styles.titleColumn}>
				Número de autenticação
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.authentication}</div>
				</div>
			</div>
			<div style={styles.column}>
				Código de barras:
				<div style={styles.column}>
					<div style={styles.copyButton}>{item?.barcode}</div>
				</div>
			</div>
		</div>
	);
};

export default TicketTemplatePDF;
