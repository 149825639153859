import * as actions from "store/actions/ticket/report";
import IReportTicket from "../types/report";

const initialState: IReportTicket = {
	items: [],
	pages: 0,
	count: 0,
	loading: false,
	value: 0,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_TICKET: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.GET_TICKET_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}
		case actions.GET_TICKET_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
