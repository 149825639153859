import {
	Backdrop,
	Button,
	Card,
	Checkbox,
	Fade,
	IconButton,
	Modal,
	Typography
} from "@material-ui/core";
import history from "AppHistory";
import DateTimePicker from "Components/DateTimePicker";
import { IconTrash } from "Components/DateTimePicker/styles";
import { exportExcel } from "Components/Report/export";
import Spinner from "Components/Spinner";
import SwitchGeneric from "Components/SwitchGeneric";
import TextInput from "Components/TextInput";
import TooltipLight from "Components/TooltipLight";
import dayjs, { Dayjs } from "dayjs";
import { cnpjMask } from "helpers/format";
import { sumCurrency } from "helpers/money";
import { toStringCurrency } from "helpers/string";
import {
	toastError,
	toastHTML,
	toastSuccess,
	toastWarning
} from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import LogRocket from "logrocket";
import { CustomArray } from "models/interfaces/columns";
import { Error } from "models/interfaces/components/error";
import { ItemsRecharge } from "models/interfaces/components/itemsRecharge";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { importTransactions } from "store/actions/accountHolderTransaction/create";
import { IAccountHolderTransactionState } from "store/actions/accountHolderTransaction/types";
import { getBalanceAccountTypes } from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import { State } from "store/reducers";
import { initialStateLogin } from "store/reducers/login/types/interfaces";

import { useStyles } from "./styles";

const parseImportError = {
	user_not_found: "ErrorUserNotFound",
	mothers_name_or_birthdate_not_found: "ErrorUserBirthdateOrMothersNameNotFound"
};

let debounceImportTransactions: (() => void) & _.Cancelable = null;

export interface ImportTATransfersProps {
	handleClose: (
		shouldOpenModalTransferTypeModal?: boolean,
		shouldOpenModalUser?: boolean,
		notOpenModals?: boolean,
		shouldOpenBusinessModal?: boolean
	) => void;
	items: Array<ItemsRecharge>;
	id: string;
	open: boolean;
	isHeadquarters: boolean;
	load: () => void;
	isTreasury?: boolean;
	isDeposit: boolean;
	isAdmin: boolean;
	shouldDisableTreasury?: boolean;
	updateTotalRechargeValue?: (value: number) => void;
	treasuryAccountBalance?: number;
}

function ImportTATransfers({
	id,
	open,
	isHeadquarters,
	items,
	handleClose,
	load,
	isDeposit,
	isTreasury,
	shouldDisableTreasury,
	isAdmin,
	updateTotalRechargeValue,
	treasuryAccountBalance
}: ImportTATransfersProps) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<State, IAccountHolderTransactionState>(
		s => s.accountHolderTransaction
	);
	const [returnErrors, setReturnErrors] = useState([]);

	const { types } = useSelector<State, IBalanceAccountState>(
		state => state.balanceAccount
	);

	const [descriptionRecharge, setDescriptionRecharge] = useState("");
	const [numberOrder, setNumberOrder] = useState("");
	const [numberContract, setNumberContract] = useState("");
	const [sendAt, setSendAt] = useState<Dayjs>(null);
	const [dueDate, setDueDate] = useState<Dayjs>(null);
	const [activeMultiCNPJ, setActiveMultiCNPJ] = useState(false);
	const [cnpj, setCnpj] = useState("");
	const [exportComponent, setExport] = useState(null);
	const [treasury, setTreasury] = useState(false);
	const [deposit, setDeposit] = useState(false);
	const [data, setData] = useState([]);
	const [loadingButton, setLoadingButton] = useState(false);
	const [openModalCalendar, setOpenModalCalendar] = useState(false);
	const [openModalCalendarDueDate, setOpenModalCalendarDueDate] =
		useState(false);

	const changeDateSendAt = useCallback(
		(dueDate: Dayjs) => {
			const existDate = Boolean(sendAt);
			const now = new Date();

			if (existDate && sendAt.isBefore(dueDate)) {
				if (dueDate && new Date(dueDate.toDate()).getDate() === now.getDate()) {
					setSendAt(
						dueDate
							.set("hour", now.getHours())
							.set("minute", now.getMinutes() + 30)
					);
				} else {
					setSendAt(
						dueDate.set("hour", sendAt.hour()).set("minute", sendAt.minute())
					);
				}
			}

			if (!existDate) {
				if (dueDate && new Date(dueDate.toDate()).getDate() === now.getDate()) {
					setSendAt(
						dueDate
							.set("hour", now.getHours())
							.set("minute", now.getMinutes() + 30)
					);
				} else {
					setSendAt(dueDate);
				}
			}

			setDueDate(dueDate);
		},
		[sendAt]
	);

	const dueDateMin = useMemo(() => {
		const now = new Date();

		if (dueDate && new Date(dueDate.toDate()).getDate() === now.getDate()) {
			const currentTime = dayjs();

			now.setHours(currentTime.hour());
			now.setMinutes(currentTime.minute());

			return now;
		}

		return now;
	}, []);

	const sendAtDateMin = useMemo(() => {
		const existDate = Boolean(dueDate);
		if (existDate) {
			return dueDate.toDate();
		}

		return new Date();
	}, [dueDate]);

	const handleOpenCalendar = useCallback(() => {
		setOpenModalCalendar(true);
	}, []);

	const handleCloseCalendar = useCallback(() => {
		setOpenModalCalendar(false);
	}, []);

	const handleOpenCalendarDueDate = useCallback(() => {
		setOpenModalCalendarDueDate(true);
	}, []);

	const handleCloseCalendarDueDate = useCallback(() => {
		setOpenModalCalendarDueDate(false);
	}, []);

	const table = useRef(null);

	const startDateVerify = useMemo(() => {
		const now = new Date();
		now.setMinutes(now.getMinutes() + 30);
		if (sendAt && new Date(sendAt.toDate()) >= now) {
			return true;
		}

		if (
			sendAt &&
			new Date(sendAt.toDate()).getDate() === now.getDate() &&
			now.getHours() < new Date(sendAt.toDate()).getHours()
		) {
			return true;
		}

		return false;
	}, [sendAt]);

	const startDueDateVerify = useMemo(() => true, []);

	const totalRechargeValue = useMemo(() => {
		const total = sumCurrency("total", data);
		updateTotalRechargeValue(total);
		return total;
	}, [data, updateTotalRechargeValue]);

	const onChange = useCallback((value: Date | string) => {
		setSendAt(dayjs(value));
	}, []);

	const onChangeDueDate = useCallback(
		(value: Date | string) => {
			changeDateSendAt(dayjs(value));
			setDueDate(dayjs(value));
		},
		[changeDateSendAt]
	);

	const excludedDates = useMemo(
		() =>
			isAdmin
				? []
				: [
						new Date("2024-12-28T03:00:00Z"),
						new Date("2024-12-29T03:00:00Z"),
						new Date("2024-12-30T03:00:00Z"),
						new Date("2024-12-31T03:00:00Z")
				  ],
		[isAdmin]
	);

	const isExecuteButtonDisabled = useMemo(() => {
		if (loading || loadingButton) return true;
		if (totalRechargeValue < 5 && deposit) return true;
		if (!deposit && !treasury) return true;
		if (deposit && !dueDate) return true;
		if (!descriptionRecharge) return true;
		if (activeMultiCNPJ && !cnpj) return true;
		if (!sendAt) return false;
		if (!startDateVerify || openModalCalendar) return true;
		return false;
	}, [
		loading,
		loadingButton,
		totalRechargeValue,
		deposit,
		treasury,
		dueDate,
		descriptionRecharge,
		activeMultiCNPJ,
		cnpj,
		sendAt,
		startDateVerify,
		openModalCalendar
	]);

	const handleActiveTreasury = useCallback(() => {
		setTreasury(true);
		setDeposit(false);
	}, []);
	const handleActiveDeposit = useCallback(() => {
		setTreasury(false);
		setDeposit(true);
	}, []);

	const handleExport = () => {
		exportExcel(
			table,
			[
				{
					Header: ls.cpfcnpj,
					accessor: "cpf"
				},
				{
					Header: ls.description,
					accessor: "description"
				}
			] as CustomArray,
			"Log",
			setExport
		);
	};

	useEffect(() => {
		setData(items || []);
	}, [items]);

	useEffect(() => {
		if (open) {
			dispatch(getBalanceAccountTypes({}));
			setTreasury(isTreasury);
			setDeposit(isDeposit);

			if (totalRechargeValue < 5 && totalRechargeValue > 0 && isDeposit) {
				toastWarning("O valor total deverá ser maior ou igual a R$5,00");
			}
		}
	}, [dispatch, isDeposit, isTreasury, open, totalRechargeValue]);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();
			setLoadingButton(true);
			const models = [];

			for (const d of data) {
				for (const key in d) {
					if (Number.isNaN(Number(key)) || !Number(d[key])) continue;

					models.push({
						document: d.document,
						type: d.type,
						balanceAccountType: Number(key),
						value: Math.round(Number(d[key]) * 100),
						description: descriptionRecharge
					});
				}

				const total = models
					.filter(c => c.document === d.document)
					.reduce((a, b) => a + b.value, 0);

				if (Math.round(d.total * 100) !== total) {
					return toastWarning(
						`Valor total(${d.total}) de ${d.name}(${
							d.document
						}) diferente do somatório dos bolsos(${total / 100.0})`
					);
				}
			}

			if (debounceImportTransactions) debounceImportTransactions.cancel();
			const newModels = [...models];

			if (deposit && dueDate)
				newModels[0] = {
					...newModels[0],
					funds: {
						paymentType: 0,
						dueDate,
						isUnified: activeMultiCNPJ,
						priorityCnpj: cnpj ? cnpj.replace(/[^\d]+/g, "") : null,
						description:
							(numberOrder ? "Número Ordem de compra: " + numberOrder : "") +
							"\n" +
							(numberContract ? "Número do Contrato:" + numberContract : "")
					}
				};

			debounceImportTransactions = _.debounce(() => {
				LogRocket.identify("ImportTreasureAccountTransfer-Create", {
					businessId: id,
					sendAt: sendAt?.toISOString()
				});

				LogRocket.debug("payload", models);

				dispatch(
					importTransactions(
						id,
						newModels,
						sendAt ? dayjs(sendAt).format() : undefined,
						err => {
							if (err) {
								setLoadingButton(false);
								if (err.default) {
									if (err.default === "Invalid priority cnpj.")
										toastError("CNPJ não encontrado");
									else toastError(err.default);
								} else if (typeof err === "string") toastError(err);
								else if (
									err.user_not_found ||
									err.mothers_name_or_birthdate_not_found
								) {
									err.user_not_found = err._original.user_not_found;

									let errors = [];

									Object.keys(err._original).map(item => {
										let errUser = err._original[item];
										try {
											if (typeof err._original[item] === "string")
												errUser = JSON.parse(err._original[item]);
										} catch (error) {
											toastError(String(error));
										}

										for (const cpf of errUser) {
											let error: Error = {
												cpf,
												description:
													ls[parseImportError[item] || "ErrorUserDesconhecido"]
											};

											errors.push(error);
										}
										return item;
									});

									handleClose(false, false, true);

									setReturnErrors(errors);

									if (isHeadquarters) {
										toastHTML(
											<div style={{ display: "flex", flexDirection: "column" }}>
												A recarga executada contém usuários com erro.
												<Button variant="contained" className={classes.button}>
													Baixar log
												</Button>
											</div>,
											handleExport
										);
									}
								} else toastError(JSON.stringify(err));
							} else {
								setLoadingButton(false);
								handleClose(false, false, true);
								load();
								toastSuccess(
									"Importação realizada com sucesso, aguarde alguns minutos até concluir a transferência"
								);
								if (deposit) history.push({ pathname: "/TreasuryAccountFlex" });
							}
						}
					)
				);
			}, 1000);

			debounceImportTransactions();

			setSendAt(undefined);
		},
		[
			activeMultiCNPJ,
			classes.button,
			cnpj,
			data,
			deposit,
			descriptionRecharge,
			dispatch,
			dueDate,
			handleClose,
			id,
			isHeadquarters,
			load,
			numberContract,
			numberOrder,
			sendAt
		]
	);

	const enableAlert = useCallback(() => {
		if (new Date() < new Date("2024-12-31")) {
			toastHTML(
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<span>
						Atenção, como não haverá expediente bancário entre os dias 28/12 e
						01/01 os boletos com carga prevista deverão ter o vencimento até o
						dia 27/12.
					</span>
					<br />
					<span>Operações via PIX estarão operando normalmente.</span>
					<Button
						variant="contained"
						style={{
							height: 20,
							width: 120,
							fontSize: 11,
							marginTop: 5,
							background: "#fff",
							color: "red"
						}}
						onClick={() => {}}
					>
						OK
					</Button>
				</div>,
				null,
				false,
				false
			);
		}
	}, []);

	const color = useCallback((id: string | number, value: string | number) => {
		if (id === "name" && !value)
			return {
				backgroundColor: "#fafafa",
				borderWidth: 1,
				borderStyle: "solid",
				borderColor: "#B30012",
				paddingLeft: 5
			};

		return {
			backgroundColor: "#fafafa"
		};
	}, []);

	const renderEditable = useCallback(
		cellInfo => {
			const nameColumn = ["name", "document"].includes(cellInfo.column.id);

			return (
				<div
					style={color(cellInfo.column.id, data[cellInfo.index].name)}
					contentEditable
					suppressContentEditableWarning
					onBlur={e => {
						const string = e.target.innerHTML;

						const updatedModel = data.map((row, index) => {
							if (index === cellInfo.index) {
								const updatedRow = { ...row };

								if (nameColumn) {
									updatedRow[cellInfo.column.id] = string;
									return updatedRow;
								}
								const number = Number(string.replace(/\D/g, ""));

								if (row[cellInfo.column.id] === number / 100) {
									return row;
								}

								if (!Number.isNaN(number)) {
									updatedRow[cellInfo.column.id] = number / 100;

									return updatedRow;
								}
								if (
									String(updatedRow[cellInfo.column.id]).replace(/\D/g, "") ===
									String(number).replace(/\D/g, "")
								)
									return updatedRow;
								updatedRow[cellInfo.column.id] = number;
								return updatedRow;
							}
							return row;
						});

						setData(updatedModel);
					}}
				>
					{!nameColumn
						? toStringCurrency(Number(data[cellInfo.index][cellInfo.column.id]))
						: data[cellInfo.index][cellInfo.column.id]}
				</div>
			);
		},
		[data]
	);
	const usersErrors = useMemo(
		() => data.filter(i => !i.name && i.error).length,
		[data]
	);

	const getFooterProps = () => ({
		style: { color: usersErrors > 0 ? "#B30012" : "#000", fontWeight: "bold" }
	});

	const columns = useMemo(() => {
		const columns = [
			{
				Header: ls.name,
				id: "name",
				accessor: c => {
					<div>{c.name}</div>;
				},
				Footer: ` ${
					usersErrors > 0
						? `${usersErrors} usuários com erro`
						: `${data.length} usuários`
				}`,
				getFooterProps,
				Cell: renderEditable
			},
			{
				id: "document",
				Header: ls.cpf,
				accessor: "document",
				Cell: renderEditable
			},
			{
				id: "total",
				Header: ls.total,
				accessor: "total",
				Footer: toStringCurrency(totalRechargeValue),
				getFooterProps,
				Cell: renderEditable
			}
		] as unknown[];

		for (const type of types) {
			if (!data.find(c => c[type.externalId])) continue;

			columns.push({
				Header: type.name,
				id: type.externalId,
				Footer: toStringCurrency(sumCurrency(type.externalId, data)),
				accessor: c => c[type.externalId],
				getFooterProps,
				Cell: renderEditable
			});
		}
		return columns;
	}, [
		usersErrors,
		data,
		getFooterProps,
		renderEditable,
		totalRechargeValue,
		types
	]);

	useEffect(() => {
		enableAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (returnErrors.length > 0) {
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => setReturnErrors([])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							As seguintes transferências não foram importados por causa dos
							seguintes erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								ref={table}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf"
										},
										{
											Header: ls.description,
											accessor: "description"
										}
									] as CustomArray
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => setReturnErrors([])}>
									{ls.close}
								</Button>
							</div>
						</div>
						{exportComponent}
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			aria-labelledby="import-user-modal-title"
			aria-describedby="import-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose(false, false, true)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">
						{ls.importBalanceAccountTransfers}
					</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<ReactTable
								data={data}
								columns={columns}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.row}>
								<Card
									style={{
										marginRight: 44,
										height: deposit ? 400 : 200,
										maxHeight: deposit ? 253 : 453
									}}
								>
									<div className={classes.containerDetail}>
										<div className={classes.containerInput}>
											<SwitchGeneric
												changeActive={
													shouldDisableTreasury
														? () => {}
														: handleActiveTreasury
												}
												active={treasury}
												containerTestId="switch-treasury-container-id"
												text="Usar saldo das tesourarias"
											/>

											<SwitchGeneric
												changeActive={handleActiveDeposit}
												active={deposit}
												containerTestId="switch-deposit-container-id"
												text={`Fazer novo depósito ${toStringCurrency(
													sumCurrency("total", data)
												)}`}
											/>
										</div>
										{deposit && (
											<>
												<div
													className={classes.dateContent}
													data-testid="duedate-datepicker-container-id"
												>
													<p
														style={{
															marginRight: 5
														}}
													>
														{ls.dueDate} :
													</p>{" "}
													<DateTimePicker
														open={openModalCalendarDueDate}
														minDate={dueDateMin}
														handleOpen={handleOpenCalendarDueDate}
														handleClose={handleCloseCalendarDueDate}
														startDateVerify={startDueDateVerify}
														date={dueDate}
														placeholder="00/00/0000 - 00:00:00"
														onChange={onChangeDueDate}
														admin={isAdmin}
													/>
													{startDueDateVerify && (
														<IconTrash>
															<TooltipLight title="Limpar" placement="top">
																<IconButton
																	onClick={() => {
																		setDueDate(null);
																	}}
																>
																	<FaTrash size={12} />
																</IconButton>
															</TooltipLight>
														</IconTrash>
													)}
												</div>
												<div
													className={classes.dateContent}
													data-testid="duedate-datepicker-container-id"
												>
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "flex-start",
															width: "100%",
															marginBottom: 10
														}}
													>
														<TextInput
															id="numberOrder"
															variant="outlined"
															type="number"
															name="numberOrder"
															value={numberOrder}
															style={{
																width: 220,
																marginRight: 20,
																height: 30,
																fontSize: 14
															}}
															onChange={(id, value) => setNumberOrder(value)}
														/>
														<TextInput
															id="numberContract"
															variant="outlined"
															type="number"
															name="numberContract"
															value={numberContract}
															style={{ width: 200, height: 30, fontSize: 14 }}
															onChange={(id, value) => setNumberContract(value)}
														/>
													</div>
												</div>
											</>
										)}
									</div>
								</Card>

								<Card
									style={{
										marginRight: 44,
										height: 200,
										maxHeight: 253
									}}
								>
									<div className={classes.containerDetail}>
										<div className={classes.containerInput}>
											<TextInput
												id="values"
												required
												variant="standard"
												label="Descrição"
												name="Valor"
												type="text"
												value={descriptionRecharge}
												error={descriptionRecharge.length > 27}
												onChange={(id, value) => {
													setDescriptionRecharge(value);
												}}
											/>
											{descriptionRecharge.length > 27 && (
												<div className={classes.labelMaxLengthDescription}>
													máximo 27 caracteres
												</div>
											)}
										</div>
										<div className={classes.dateContent}>
											<p style={{ marginRight: 5 }}>{ls.scheduleFor} :</p>{" "}
											<DateTimePicker
												open={openModalCalendar}
												openTime
												minDate={sendAtDateMin}
												dateFormaTime
												handleOpen={handleOpenCalendar}
												handleClose={handleCloseCalendar}
												startDateVerify={startDateVerify}
												date={sendAt}
												placeholder="00/00/0000 - 00:00:00"
												onChange={onChange}
												admin={isAdmin}
											/>
											{startDateVerify && (
												<IconTrash>
													<TooltipLight title="Limpar" placement="top">
														<IconButton
															onClick={() => {
																setSendAt(dueDate);
															}}
														>
															<FaTrash size={12} />
														</IconButton>
													</TooltipLight>
												</IconTrash>
											)}
										</div>
									</div>
								</Card>
								{deposit && (
									<div
										data-testid="active-multicpnj-id"
										className={classes.containerDetail}
										style={{
											alignSelf: "flex-start",
											padding: 0,
											paddingBottom: 0
										}}
									>
										<div className={classes.row} style={{ marginTop: 0 }}>
											<Checkbox
												id="isStore"
												name="store"
												color="primary"
												checked={activeMultiCNPJ}
												onChange={() => setActiveMultiCNPJ(!activeMultiCNPJ)}
											/>
											<p style={{ marginRight: 5 }}>{ls.unifiqueNF}</p>
										</div>
										{activeMultiCNPJ && (
											<div
												className={classes.dateMultiCNPJ}
												data-testid="multicnpj-input-id"
											>
												<p style={{ marginRight: 9, fontSize: 16 }}>
													{ls.insertCNPJ}
												</p>
												<TextInput
													size="small"
													id="cnpj"
													placeholder="00.000.000/0001-00"
													variant="outlined"
													hideLabel
													InputProps={{
														classes: { input: classes.input }
													}}
													name="cnpj"
													value={cnpjMask(cnpj)}
													onChange={(id, value) => {
														setCnpj(value);
													}}
												/>
											</div>
										)}
									</div>
								)}
							</div>
							<div className={classes.footer}>
								<Button
									variant="contained"
									style={{ color: "#FFFFFF" }}
									className={classes.buttonFooter}
									onClick={() => handleClose(false, false, true)}
								>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={isExecuteButtonDisabled || usersErrors > 0}
									className={classes.buttonFooter}
								>
									{loading ? (
										<Spinner color="secondary" size={16} />
									) : (
										ls.executed
									)}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportTATransfers;
