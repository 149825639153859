import { PlatformWiipoProvider } from "contexts/PlatformWiipo";
import * as H from "history";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { isLogged } from "../../helpers/auth";
import ChangePassword from "../ChangePassword";
import Dashboard from "../Dashboard";
import Login from "../Login";
import RecoverPassword from "../RecoverPassword";

interface Props {
	history: H.History<any>;
}

function AppRouter({ history }: Props) {
	const login = useSelector((s: any) => s.login);

	let loggedIn = isLogged(login);

	return (
		<Router history={history}>
			<PlatformWiipoProvider>
				<Switch>
					<Route
						exact
						path="/Login"
						render={() => (loggedIn ? <Redirect to="/" /> : <Login />)}
					/>
					<Route
						exact
						path="/RecoverPassword"
						render={() =>
							loggedIn ? <Redirect to="/" /> : <RecoverPassword />
						}
					/>
					<Route
						exact
						path="/ChangePassword"
						render={() => (loggedIn ? <Redirect to="/" /> : <ChangePassword />)}
					/>
					<Route
						path="/"
						render={() =>
							loggedIn ? (
								<Dashboard history={history} />
							) : (
								<Redirect to="/Login" />
							)
						}
					/>
					<Redirect to="/login" />
				</Switch>
			</PlatformWiipoProvider>
		</Router>
	);
}

export default AppRouter;
