import { combineReducers } from "redux";
import { IAccountHolderTransactionState } from "store/actions/accountHolderTransaction/types";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import { ITreasuryAccountWiipoFlexState } from "store/actions/invoice/types";
import { IInvoiceClubeWiipoState } from "store/actions/invoicingClubeWiipo/types";
import { USER_LOGGED_OUT } from "store/actions/login";
import { ITreasuryAccountTransferState } from "store/actions/treasuryAccountTransfer/types";
import { ICreateTransfer } from "store/actions/treasuryAccountWiipoFlex/types";

import accession from "./accession";
import { IAccession } from "./accession/types";
import accountHolderTransaction from "./accountHolderTransaction";
import allowance from "./allowance";
import { IAllowance } from "./allowance/types";
import balanceAccount from "./balanceAccount";
import balanceAccountTransfer from "./balanceAccount/transfer";
import business from "./business";
import businessCategoryCreate from "./business/category/create";
import businessCategoryEdit from "./business/category/edit";
import businessCategoryReport from "./business/category/report";
import { ICategoryCreate } from "./business/category/types/create";
import { ICategoryEdit } from "./business/category/types/edit";
import { ICategoryReport } from "./business/category/types/report";
import businessCreate from "./business/create";
import businessBatch from "./business/createBatch";
import dataDankBusiness from "./business/dataBank/get";
import businessDetail from "./business/detail";
import businessEdit from "./business/edit";
import helpiiLimit, { IHelpiiLimit } from "./business/helpiiLimit";
import limitClubCreate from "./business/limitClub";
import businessPolicy from "./business/policy";
import businessReport from "./business/report";
import businessReportBranches from "./business/report/branches";
import { IBusiness } from "./business/types";
import { IBusinessBranches } from "./business/types/branches";
import { IBusinessCreate } from "./business/types/create";
import { IBusinessDataBank } from "./business/types/dataBank";
import { IBusinessDetail } from "./business/types/detail";
import { IBusinessEdit } from "./business/types/edit";
import { IBusinessLimits } from "./business/types/limit";
import { IBusinessPolicy } from "./business/types/policy";
import { IBusinessReport } from "./business/types/report";
import campaignCreateEdit, {
	CampaignCreateEditState
} from "./campaign/createEdit";
import campaignList, { CampaignListState } from "./campaign/list";
import card, { IModelResult } from "./card";
import cashbackReport from "./cashback/report";
import { IReportCashback } from "./cashback/types";
import cashbackPolicy, { CashbackPolicyState } from "./cashbackPolicy";
import chargeCreate from "./charge/create";
import chargeDelete from "./charge/delete";
import chargeReport from "./charge/report";
import { ICreateCharge } from "./charge/types/create";
import { IDeleteCharge } from "./charge/types/delete";
import { IReportCharge } from "./charge/types/report";
import checkout from "./checkout";
import { ICheckout } from "./checkout/types";
import reportComponent from "./component/report";
import configurationDetail from "./configuration/detail";
import { IConfigurationDetail } from "./configuration/detail/types";
import configurationEdit from "./configuration/edit";
import { IConfigurationEdit } from "./configuration/edit/types";
import consumptionPolicy from "./consumptionPolicy";
import { IConsumptionPolicy } from "./consumptionPolicy/types";
import convenienceFee from "./convenienceFee";
import { IConvenienceFee } from "./convenienceFee/types";
import costCenter, { CostCenterState } from "./costCenter";
import creditCreate from "./credit/create";
import creditDetail from "./credit/detail";
import creditEdit from "./credit/edit";
import creditImport from "./credit/import";
import creditReport from "./credit/report";
import { ICreateCredit } from "./credit/types/create";
import { IDetailCredit } from "./credit/types/detail";
import { IEditCredit } from "./credit/types/edit";
import { IImport } from "./credit/types/import";
import { IReportCredit } from "./credit/types/report";
import dashboard from "./dashboard";
import { IDashboard } from "./dashboard/types";
import deliveryAddress from "./deliveryAddress";
import { IDeliveryAddress } from "./deliveryAddress/types";
import exportHistory from "./exportHistory";
import { IExportHistory } from "./exportHistory/types";
import header from "./header";
import notificationUser from "./header/notificationUser";
import { IHeader } from "./header/types";
import { INotificationUser } from "./header/types/notificationUser";
import installmentEdit from "./installment/edit";
import installmentReport from "./installment/report";
import { IEditInstallment } from "./installment/types/edit";
import { IReportInstallment } from "./installment/types/report";
import integrations from "./integrations";
import { IIntegrations } from "./integrations/types";
import invoiceReport from "./invoice";
import invoicingClubeWiipo from "./invoiceClubeWiipo";
import login from "./login";
import { initialStateLogin } from "./login/types/interfaces";
import marketing from "./marketing";
import { IMarketingState } from "./marketing/types";
import moderationCreate from "./moderation/create";
import moderationDetail from "./moderation/detail";
import moderationEdit from "./moderation/edit";
import moderationReport from "./moderation/report";
import { ICreateModeration } from "./moderation/types/create";
import { IDetailModeration } from "./moderation/types/detail";
import { IEditModeration } from "./moderation/types/edit";
import { IReportModeration } from "./moderation/types/report";
import orderDetail from "./order/detail";
import orderEdit from "./order/edit";
import invoiceOrderViaVarejo from "./order/invoiceOrderViaVarejo";
import orderReport from "./order/report";
import trackingOrderViaVarejo from "./order/trackingOrderViaVarejo";
import { IDetailOrder } from "./order/types/detail";
import { IEditOrder } from "./order/types/edit";
import { IReportOrder } from "./order/types/report";
import product from "./product";
import productBanners from "./product/banners";
import { IProduct } from "./product/types";
import { IBanners } from "./product/types/banners";
import productCategory from "./productCategory";
import { IProductCategory } from "./productCategory/types";
import productManagerAccount from "./productManagerAccount";
import { IProductManagerAccount } from "./productManagerAccount/types";
import productPromotion from "./productPromotion";
import { IProductPromotion } from "./productPromotion/types/productPromotion";
import requestCreate from "./requestCards/create";
import requestDetail from "./requestCards/detail";
import requestReport from "./requestCards/report";
import roleCreate from "./role/create";
import roleDetails from "./role/details";
import { IDetailRole } from "./role/types/details";
import taxReport from "./tax";
import transactionCreate from "./transaction/create";
import transactionDetail from "./transaction/detail";
import transactionEdit from "./transaction/edit";
import transactionImport, { IInitialState } from "./transaction/import";
import transactionReport from "./transaction/report";
import { ICreateTransaction } from "./transaction/types/create";
import { IDetailTransaction } from "./transaction/types/detail";
import { IEditTransaction } from "./transaction/types/edit";
import { IReportTransaction } from "./transaction/types/report";
import treasuryAccountTransfer from "./treasuryAccountTransfer";
import treasuryAccountWiipoFlexCreate from "./treasuryAccountWiipoFlex/create";
import { ICreate } from "./treasuryAccountWiipoFlex/create/types";
import treasuryAccountWiipoFlexCreateTransfer from "./treasuryAccountWiipoFlex/createTransfer";
import treasuryAccountWiipoFlexEditStatus from "./treasuryAccountWiipoFlex/edit/status";
import treasuryAccountWiipoFlexNF from "./treasuryAccountWiipoFlex/nf";
import treasuryAccountWiipoFlexDeposit from "./treasuryAccountWiipoFlex/report/deposit";
import treasuryAccountWiipoFlexTransfer from "./treasuryAccountWiipoFlex/report/transfer";
import { stateBalanceAccountTransfer } from "./types/balanceAccount";
import userCalcLimit from "./user/calcLimit";
import userConsultHCM from "./user/consultHCM";
import userCreate from "./user/create";
import userDetail from "./user/detail";
import userEdit from "./user/edit";
import userExport from "./user/export";
import userImport from "./user/import";
import refreshSwap from "./user/refreshSwap";
import userReport from "./user/report";
import ticketReport from "./ticket/report";
import { ICreateUser } from "./user/types/create";
import { IDetailUser } from "./user/types/detail";
import { IEditUser } from "./user/types/edit";
import { IRefreshSwap } from "./user/types/refreshSwap";
import { IReportUser } from "./user/types/report";
import virtualCard from "./virtualCard";
import { IVirtualCard } from "./virtualCard/types";
import world from "./world";
import { IWorldState } from "./world/types";
import IReportTicket from "./ticket/types/report";

export interface State {
	accession: IAccession;
	accountHolderTransaction: IAccountHolderTransactionState;
	allowance: IAllowance;
	balanceAccount: IBalanceAccountState;
	balanceAccountTransfer: stateBalanceAccountTransfer;
	business: IBusiness;
	businessCategoryCreate: ICategoryCreate;
	businessCategoryEdit: ICategoryEdit;
	businessCategoryReport: ICategoryReport;
	businessCreate: IBusinessCreate;
	businessDetail: IBusinessDetail;
	businessEdit: IBusinessEdit;
	businessPolicy: IBusinessPolicy;
	businessReport: IBusinessReport;
	businessReportBranches: IBusinessBranches;
	card: IModelResult;
	cashbackPolicy: CashbackPolicyState;
	cashbackReport: IReportCashback;
	chargeCreate: ICreateCharge;
	chargeDelete: IDeleteCharge;
	chargeReport: IReportCharge;
	checkout: ICheckout;
	configurationDetail: IConfigurationDetail;
	configurationEdit: IConfigurationEdit;
	consumptionPolicy: IConsumptionPolicy;
	convenienceFee: IConvenienceFee;
	costCenter: CostCenterState;
	creditCreate: ICreateCredit;
	creditDetail: IDetailCredit;
	creditEdit: IEditCredit;
	creditImport: IImport;
	creditReport: IReportCredit;
	dashboard: IDashboard;
	deliveryAddress: IDeliveryAddress;
	exportHistory: IExportHistory;
	header: IHeader;
	notificationUser: INotificationUser;
	installmentEdit: IEditInstallment;
	installmentReport: IReportInstallment;
	integrations: IIntegrations;
	invoiceReport: ITreasuryAccountWiipoFlexState;
	limitClubCreate: IBusinessLimits;
	login: initialStateLogin;
	marketing: IMarketingState;
	moderationCreate: ICreateModeration;
	moderationDetail: IDetailModeration;
	moderationEdit: IEditModeration;
	moderationReport: IReportModeration;
	orderDetail: IDetailOrder;
	orderEdit: IEditOrder;
	orderReport: IReportOrder;
	trackingOrderViaVarejo: IDetailOrder;
	invoiceOrderViaVarejo: IDetailOrder;
	product: IProduct;
	productBanners: IBanners;
	productCategory: IProductCategory;
	productManagerAccount: IProductManagerAccount;
	productPromotion: IProductPromotion;
	reportComponent: any;
	requestCreate: IInitialState;
	requestDetail: any;
	requestReport: any;
	refreshSwap: IRefreshSwap;
	roleCreate: IRefreshSwap;
	roleDetails: IDetailRole;
	transactionCreate: ICreateTransaction;
	transactionDetail: IDetailTransaction;
	transactionEdit: IEditTransaction;
	transactionReport: IReportTransaction;
	transactionImport: IInitialState;
	treasuryAccountTransfer: ITreasuryAccountTransferState;
	treasuryAccountWiipoFlexCreate: ICreate;
	treasuryAccountWiipoFlexEditStatus: IRefreshSwap;
	treasuryAccountWiipoFlexNF: IRefreshSwap;
	treasuryAccountWiipoFlexDeposit: ITreasuryAccountWiipoFlexState;
	treasuryAccountWiipoFlexTransfer: ITreasuryAccountWiipoFlexState;
	treasuryAccountWiipoFlexCreateTransfer: ICreateTransfer;
	ticketReport: IReportTicket;
	userCalcLimit: IRefreshSwap;
	userConsultHCM: IRefreshSwap;
	userCreate: ICreateUser;
	userDetail: IDetailUser;
	userEdit: IEditUser;
	userExport: IRefreshSwap;
	userImport: IRefreshSwap;
	userReport: IReportUser;
	virtualCard: IVirtualCard;
	world: IWorldState;
	invoicingClubeWiipo: IInvoiceClubeWiipoState;
	dataDankBusiness: IBusinessDataBank;
	campaignList: CampaignListState;
	campaignCreateEdit: CampaignCreateEditState;
	helpiiLimit: IHelpiiLimit;
}

const reducers = {
	accession,
	accountHolderTransaction,
	allowance,
	balanceAccount,
	balanceAccountTransfer,
	business,
	businessBatch,
	businessCategoryCreate,
	businessCategoryEdit,
	businessCategoryReport,
	businessCreate,
	businessDetail,
	businessEdit,
	businessPolicy,
	businessReport,
	businessReportBranches,
	card,
	cashbackPolicy,
	cashbackReport,
	chargeCreate,
	chargeDelete,
	chargeReport,
	checkout,
	configurationDetail,
	configurationEdit,
	consumptionPolicy,
	convenienceFee,
	costCenter,
	creditCreate,
	creditDetail,
	creditEdit,
	creditImport,
	creditReport,
	dashboard,
	deliveryAddress,
	exportHistory,
	header,
	notificationUser,
	installmentEdit,
	installmentReport,
	integrations,
	invoiceReport,
	limitClubCreate,
	login,
	marketing,
	moderationCreate,
	moderationDetail,
	moderationEdit,
	moderationReport,
	orderDetail,
	orderEdit,
	orderReport,
	trackingOrderViaVarejo,
	invoiceOrderViaVarejo,
	product,
	productBanners,
	productCategory,
	productManagerAccount,
	productPromotion,
	reportComponent,
	refreshSwap,
	requestCreate,
	requestDetail,
	requestReport,
	roleCreate,
	roleDetails,
	taxReport,
	transactionCreate,
	transactionDetail,
	transactionEdit,
	transactionReport,
	transactionImport,
	treasuryAccountTransfer,
	treasuryAccountWiipoFlexCreate,
	treasuryAccountWiipoFlexEditStatus,
	treasuryAccountWiipoFlexNF,
	treasuryAccountWiipoFlexDeposit,
	treasuryAccountWiipoFlexTransfer,
	treasuryAccountWiipoFlexCreateTransfer,
	ticketReport,
	userCalcLimit,
	userConsultHCM,
	userCreate,
	userDetail,
	userEdit,
	userExport,
	userImport,
	userReport,
	virtualCard,
	world,
	invoicingClubeWiipo,
	dataDankBusiness,
	campaignList,
	campaignCreateEdit,
	helpiiLimit
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === USER_LOGGED_OUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
