import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	button: {
		textTransform: "lowercase"
	},
	dialog: {
		borderRadius: 16,
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
	},
	title: {
		fontWeight: "bold",
		fontSize: theme.typography.pxToRem(20),
		padding: theme.spacing(2),
		paddingBottom: 0
	}
}));

export default useStyles;
