import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Typography
} from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import HelpiiActivatedDialog from "Components/HelpiiLimit/HelpiiActivatedDialog";
import HelpiiDesactivateDialog from "Components/HelpiiLimit/HelpiiDesactivateDialog";
import { MONEY } from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAccountTypeForBusiness } from "store/actions/balanceAccount/report";
import {
	IBalanceAccountState,
	IBalanceAccountType
} from "store/actions/balanceAccount/types";
import {
	fetchHelpiiLimit,
	updateHelpiiLimit
} from "store/actions/business/helpiiLimit";
import { updateBusinessPolicy } from "store/actions/business/policy";
import { State } from "store/reducers";
import { IHelpiiLimit } from "store/reducers/business/helpiiLimit";

import useStyles from "./styles";

interface Props {
	businessId: string;
}

const HelpiiLimit: React.FC<Props> = ({ businessId }) => {
	const styles = useStyles({});
	const dispatch = useDispatch();
	const { types } = useSelector<State, IBalanceAccountState>(
		state => state.balanceAccount
	);
	const { limit, loading: loadingHelpiiTotalLimit } = useSelector<
		State,
		IHelpiiLimit
	>(state => state.helpiiLimit);

	const [active, setActive] = useState(false);
	const [helpiiBag, setHelpiiBag] = useState<IBalanceAccountType | undefined>();
	const [loading, setLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openActivatedDialog, setActivatedDialog] = useState(false);
	const [editable, setEditable] = useState(false);
	const [limitFromBusiness, setLimitFromBusiness] = useState(
		limit?.totalFromCompany / 100
	);
	const handleStatusChange = () => {
		setLoading(true);
		const status = !active;
		dispatch(
			updateBusinessPolicy(
				[
					{
						prePaidBalanceAccountTypeId: helpiiBag?.id,
						status
					}
				],
				(err: any) => {
					if (err) {
						if (err.default) {
							toastError("Você não tem permissão para alterar este bolso.");
						} else {
							toastError(err);
						}
					} else {
						dispatch(getBalanceAccountTypeForBusiness(businessId, false));
						if (status) {
							setActivatedDialog(true);
						} else {
							setOpenDialog(false);
						}
						setActive(status);
					}
					setLoading(false);
				}
			)
		);
	};

	const onClickChangeStatus = () => {
		if (active) {
			setOpenDialog(true);
		} else {
			handleStatusChange();
		}
	};

	const onClose = () => {
		setOpenDialog(false);
	};

	const onClickCheckButton = () => {
		dispatch(
			updateHelpiiLimit(businessId, limitFromBusiness, () => {
				setEditable(false);
			})
		);
	};

	useEffect(() => {
		if (types?.length) {
			const bag = types.find(t => t.externalId === "0");
			if (bag) {
				const business = bag?.businesses?.find(b => b.status === 1);
				setHelpiiBag(bag);
				setActive(!!business);
			}
		}
	}, [types]);

	useEffect(() => {
		if (!types?.length)
			dispatch(getBalanceAccountTypeForBusiness(businessId, false));
	}, [businessId, dispatch, types]);

	useEffect(() => {
		dispatch(fetchHelpiiLimit(businessId));
	}, []);

	useEffect(() => {
		setLimitFromBusiness(limit?.totalFromCompany / 100);
	}, [limit?.totalFromCompany]);

	if (!helpiiBag) return null;

	return (
		<>
			<Paper className={styles.root}>
				<div className={styles.header}>
					<Typography variant="h6">{ls.helpiiLimit}</Typography>

					{loading ? (
						<Spinner size={20} />
					) : (
						<div>
							{active ? (
								<Button
									color="primary"
									onClick={onClickChangeStatus}
									style={{
										textTransform: "initial",
										fontWeight: "bold"
									}}
								>
									{ls.temporarilyInactivate}
								</Button>
							) : (
								<Button
									color="primary"
									onClick={onClickChangeStatus}
									style={{
										textTransform: "initial",
										fontWeight: "bold"
									}}
								>
									{ls.activate}
								</Button>
							)}
						</div>
					)}
				</div>
				<hr className={styles.line} />
				<Grid container spacing={2} className={styles.form} alignItems="center">
					{loadingHelpiiTotalLimit || loading ? (
						<Grid container direction="row" spacing={2}>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Skeleton variant="rect" height={50} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Skeleton variant="rect" height={50} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Skeleton variant="rect" height={50} />
							</Grid>
						</Grid>
					) : (
						<>
							<Grid
								container
								xs={12}
								sm={6}
								md={3}
								lg={3}
								direction="row"
								alignItems="center"
							>
								<strong>{ls.available}</strong>:{" "}
								<Box
									display={"flex"}
									gridColumnGap={1}
									marginLeft={1}
									alignItems={"center"}
									flex={1}
								>
									{editable ? (
										<TextInput
											id="businessTotalLimit"
											required
											variant="outlined"
											name="businessTotalLimit"
											disabled={!editable}
											InputProps={{
												inputComponent: MONEY
											}}
											style={{
												minWidth: 90
											}}
											value={limitFromBusiness}
											onFocus={e => e.target.select()}
											onChange={(_, v) => setLimitFromBusiness(+v)}
										/>
									) : (
										<MONEY
											displayType="text"
											value={limit?.totalFromCompany / 100 || 0}
										/>
									)}
									<Box display={"flex"} alignItems={"center"}>
										{editable && (
											<IconButton onClick={onClickCheckButton}>
												<Check color="primary" />
											</IconButton>
										)}
										<IconButton onClick={() => setEditable(!editable)}>
											{!editable ? <Edit /> : <Close />}
										</IconButton>
									</Box>
								</Box>
							</Grid>
							<Grid container xs={12} sm={6} md={3} lg={3}>
								<strong>{ls.distributed}</strong>:{" "}
								<MONEY
									style={{
										marginLeft: 4
									}}
									displayType="text"
									value={limit?.totalFromUsers / 100 || 0}
								/>
							</Grid>
							<Grid container xs={12} sm={6} md={3} lg={3}>
								<strong>{ls.status}</strong>:{" "}
								<strong
									style={{
										color: active ? "#009729" : "red",
										marginLeft: 4
									}}
								>
									{active ? ls.active : ls.disabled}
								</strong>
							</Grid>
						</>
					)}
				</Grid>
			</Paper>
			<HelpiiDesactivateDialog
				open={openDialog}
				onClose={onClose}
				handleStatusChange={handleStatusChange}
				loading={loading}
			/>
			<HelpiiActivatedDialog
				open={openActivatedDialog}
				onClose={() => setActivatedDialog(false)}
			/>
		</>
	);
};

export default HelpiiLimit;
