import * as api from "services/api";

export const FETCH_HELPII_LIMIT = "FETCH_HELPII_LIMIT";
export const FETCH_HELPII_LIMIT_SUCCESS = "FETCH_HELPII_LIMIT_SUCCESS";
export const FETCH_HELPII_LIMIT_FAILED = "FETCH_HELPII_LIMIT_FAILED";

export const UPDATE_HELPII_LIMIT = "UPDATE_HELPII_LIMIT";
export const UPDATE_HELPII_LIMIT_SUCCESS = "UPDATE_HELPII_LIMIT_SUCCESS";
export const UPDATE_HELPII_LIMIT_FAILED = "UPDATE_HELPII_LIMIT_FAILED";

export const fetchHelpiiLimit = (id: string) => async (dispatch, getState) => {
	try {
		const { login } = getState();
		dispatch({ type: FETCH_HELPII_LIMIT });

		const response = await api.sendGet(`/v2/Business/${id}/Credit/Limit`, {
			Authorization: `Bearer ${login.token}`
		});

		const result = await response.json();

		dispatch({
			type: FETCH_HELPII_LIMIT_SUCCESS,
			payload: result?.model
		});
	} catch (error) {
		dispatch({ type: FETCH_HELPII_LIMIT_FAILED });
	}
};

export const updateHelpiiLimit =
	(id: string, limit: number, callback: () => void) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: UPDATE_HELPII_LIMIT });
			const { login } = getState();

			const response = await api.sendPut(
				`/v2/Business/${id}/Credit/Limit?total=${limit * 100}`,
				{},
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = await response.json();

			if (result?.model) {
				dispatch({
					type: UPDATE_HELPII_LIMIT_SUCCESS,
					payload: result?.model?.total
				});
				if (callback) {
					callback();
				}
			} else {
				throw new Error("Erro ao atualizar limite");
			}
		} catch (error) {
			dispatch({ type: UPDATE_HELPII_LIMIT_FAILED });
			callback();
		}
	};
