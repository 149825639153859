import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
	fab: {
		margin: theme.spacing(),
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	headerRightContainer: {
		height: "100%"
	},
	button: {
		height: 20,
		width: "100%",
		fontSize: 11,
		marginTop: 5,
		background: "#fff",
		color: "red"
	}
}));

export default useStyles;
