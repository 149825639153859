import * as actions from "store/actions/business/helpiiLimit";

export interface IHelpiiLimit {
	limit: {
		totalFromUsers: number;
		totalFromCompany: number;
	};
	loading: boolean;
}

const initialState: IHelpiiLimit = {
	limit: undefined,
	loading: false
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.FETCH_HELPII_LIMIT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.FETCH_HELPII_LIMIT_SUCCESS: {
			return {
				...state,
				loading: false,
				limit: payload
			};
		}
		case actions.FETCH_HELPII_LIMIT_FAILED:
			return {
				...state,
				loading: false
			};

		case actions.UPDATE_HELPII_LIMIT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.UPDATE_HELPII_LIMIT_SUCCESS: {
			return {
				...state,
				loading: false,
				limit: {
					...state.limit,
					totalFromCompany: payload
				}
			};
		}
		case actions.UPDATE_HELPII_LIMIT_FAILED:
			return {
				...state,
				loading: false
			};
		default: {
			return state;
		}
	}
};

export default reducer;
