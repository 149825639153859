import { getErrors } from "helpers/error";
import { toastSuccess } from "helpers/toast";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const EXPORT_USERS_DATA = "[DATA EXPORT] EXPORT_USERS_DATA";
export const EXPORT_USERS_DATA_SUCCESS =
	"[DATA EXPORT] EXPORT_USERS_DATA_SUCCESS";
export const EXPORT_USERS_DATA_FAILED =
	"[DATA EXPORT] EXPORT_USERS_DATA_FAILED";

export const SET_VALUE = "[DATA EXPORT] SET_VALUE";
export const CLEAR_VALUES = "[DATA EXPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function exportUsersData(businessId, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: EXPORT_USERS_DATA });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/User/Export?businessId=${businessId}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				toastSuccess(
					"Seu relatório está sendo gerado e será enviado para seu e-mail."
				);
				dispatch({
					type: EXPORT_USERS_DATA_SUCCESS,
					payload: result.model
				});

				callback && callback(null);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EXPORT_USERS_DATA_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EXPORT_USERS_DATA_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
