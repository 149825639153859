import * as api from "services/api";

export const GET_BALANCEACCOUNT_TRANSFER =
	"[BALANCEACCOUNT TRANSFER] GET_BALANCEACCOUNT_TRANSFER";
export const GET_BALANCEACCOUNT_TRANSFER_SUCCESS =
	"[BALANCEACCOUNT TRANSFER] GET_BALANCEACCOUNT_TRANSFER_SUCCESS";
export const GET_BALANCEACCOUNT_TRANSFER_FAILED =
	"[BALANCEACCOUNT TRANSFER] GET_BALANCEACCOUNT_TRANSFER_FAILED";

export const GET_BUSINESS_TINY = "[BALANCEACCOUNT TRANSFER] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS =
	"[BALANCEACCOUNT TRANSFER] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED =
	"[BALANCEACCOUNT TRANSFER] GET_BUSINESS_TINY_FAILED";

export const SET_VALUE = "[BALANCEACCOUNT TRANSFER] SET_VALUE";
export const CLEAR_VALUES = "[BALANCEACCOUNT TRANSFER] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

interface PropsPutBalance {
	businessId: string;
	data: any[];
	callback: (err, model) => void;
}

interface PropsGetBalance {
	businessId: string;
	balanceAccountId: string;
	callback: (err, model) => void;
}

interface PropsGetVerifyBalance {
	businessId: string;
	treasuryAccountTransferId: string;
	callback: (err, model) => void;
}

interface CreateBag {
	userId: string;
	accountHolderId: string;
	accountType: number;
	status: number;
	callback: (err, model) => void;
}

export const getVerifyBalanceAccountTransfer =
	({
		businessId,
		treasuryAccountTransferId,
		callback
	}: PropsGetVerifyBalance) =>
	async (dispatch, getState): Promise<any> => {
		dispatch({ type: GET_BALANCEACCOUNT_TRANSFER });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/${businessId}/BalanceAccount/Transfer/${treasuryAccountTransferId}/CountUserAlreadyReceivedSameValueSameBagToday`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: GET_BALANCEACCOUNT_TRANSFER_SUCCESS,
					payload: result.model
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BALANCEACCOUNT_TRANSFER_FAILED
			});
		}
	};

export const createNewBag =
	({ userId, accountHolderId, accountType, status, callback }: CreateBag) =>
	async (dispatch, getState): Promise<any> => {
		dispatch({ type: GET_BALANCEACCOUNT_TRANSFER });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/UserBagAccount`,
				{ userId, accountHolderId, accountType, status },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: GET_BALANCEACCOUNT_TRANSFER_SUCCESS,
					payload: result.model
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BALANCEACCOUNT_TRANSFER_FAILED
			});
		}
	};

export const getBalanceAccountTransfer =
	({ businessId, balanceAccountId, callback }: PropsGetBalance) =>
	async (dispatch, getState): Promise<any> => {
		dispatch({ type: GET_BALANCEACCOUNT_TRANSFER });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/${businessId}/PrePaid/BalanceAccount/Type/${balanceAccountId}/Transfer/Destiny`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: GET_BALANCEACCOUNT_TRANSFER_SUCCESS,
					payload: result.model
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BALANCEACCOUNT_TRANSFER_FAILED
			});
		}
	};

export const putBalanceAccountTransfer =
	({ businessId, data, callback }: PropsPutBalance) =>
	async (dispatch, getState): Promise<any> => {
		dispatch({ type: GET_BALANCEACCOUNT_TRANSFER });

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/Business/${businessId}/PrePaid/BalanceAccount/Type/Transfer/Destiny`,
				[...data],
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: GET_BALANCEACCOUNT_TRANSFER_SUCCESS,
					payload: result.model
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BALANCEACCOUNT_TRANSFER_FAILED
			});
		}
	};
